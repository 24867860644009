import { TUserRecentViewItem } from '../../../common/rest/userRecentView/userRecentViewRest';
import { IRecentsSetAction, RECENTS_SET_ACTION } from './types';

export class RecentsActions {

  public static set(
    list: TUserRecentViewItem[]
  ): IRecentsSetAction {
    return {
      type: RECENTS_SET_ACTION,
      list
    }
  }

}