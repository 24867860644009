import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { VideoLibraryGroupItem } from '../components/VideoLibraryGroupItem';
import { IVideoLibraryGroupItemEvents, IVideoLibraryGroupItemFields } from '../components/types';
import { IVideoLibraryGroupItemHOCOwnProps } from './types';
import { VideoLibraryGroupItemEvents } from './events';
import { getGroupTargetLanguage, getUserGroupById } from '../../../../../store/models/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    {isOpened, video, groupId}: IVideoLibraryGroupItemHOCOwnProps
  ): IVideoLibraryGroupItemFields | null => {
    const {author, thumb, title} = video.info;
    const group = getUserGroupById(state, groupId);
    const gropuLang = group && getGroupTargetLanguage(state, group);
    const langCodes = gropuLang ? [gropuLang.code] : null as any;
    return {
      isOpened,
      author,
      thumb,
      title,
      langCodes,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {groupId, video}: IVideoLibraryGroupItemHOCOwnProps
): IVideoLibraryGroupItemEvents => ({
    onClick: () => dispatch(VideoLibraryGroupItemEvents.onClick(video.videoKey, groupId)),
  }
);

export const VideoLibraryGroupItemHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoLibraryGroupItem);

VideoLibraryGroupItemHOC.displayName = 'VideoLibraryGroupItemHOC';