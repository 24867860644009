import { getOneTimeTooltipsStorage } from '../../../common/utils/local-storage/onetime-tooltips';
import { getDispatch, getState } from '../../store';
import { setOneTimeTooltipAction } from '../../store/general/actions';
import { getOneTimeTooltip } from '../../store/general/selectors';

export class FullScreen {
	public static toggle() {
		const root = document.querySelector('.root-inner');
		if (root?.classList.contains('fullscreen')) {
			this.off();
		} else {
			this.on();
		}
	}

	public static on() {
		const root = document.body;
		if (root && root.requestFullscreen) {
			let w = window as any;
			w.setFullScreen(true);
			root.requestFullscreen();

			if (getOneTimeTooltip(getState()).showFullscreen) {
				getOneTimeTooltipsStorage().setShow({ showFullscreen: false });
				getDispatch()(setOneTimeTooltipAction({ showFullscreen: false }));
			}
		}
	}

	public static off() {
		if (document.fullscreenElement && document.exitFullscreen) {
			document.exitFullscreen();
		}
		let w = window as any;
		w.setFullScreen(false);
	}

}
