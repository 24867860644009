import { TUserRecentViewItem } from '../../../common/rest/userRecentView/userRecentViewRest';

export const RECENTS_SET_ACTION = 'RECENTS/RECENTS_SET_ACTION';

export interface IRecentsSetAction {
  type: typeof RECENTS_SET_ACTION;
  list: TUserRecentViewItem[];
}

export interface IRecentsState {
  list: TUserRecentViewItem[];
}

export type TRecentsActions =
  | IRecentsSetAction
