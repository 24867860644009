import { API_URL } from '../../constants';
import { TAudio } from '../../../spa/store/models/types';
import { IPhraseNote, IVocabularyPhrase } from '../../../spa/types/common';
import { getRequest, postRequest } from '../../utils/requestService';
import { getDispatch } from '../../../spa/store';

export interface IRestAudioGroup extends TAudio {
  phrases: IVocabularyPhrase[];
  phraseNotes: IPhraseNote[];
  position: number;
  audio: TAudio
}

interface IRestAudioCaptionWord {
  text: string,
  time: number,
  startLetterIndex?: number,
  endLetterIndex?: number
}

export interface IRestAudioCaption {
  startTime: number,
  endTime: number,
  words: IRestAudioCaptionWord[],
  captionText?: string
}

export interface IRestAudioLang {
  code: string,
  title: string
}

export interface IRestAudioLangDetectResult {
  langs: IRestAudioLang[],
  findIndex: number
}


export class AudioRest {

  private static FETCH_URL = API_URL + '/audio/info';
  private static GET_CAPTIONS_URL = API_URL + '/audio/captions';
  private static ADD_URL = API_URL + '/audio/add';
  private static REMOVE_URL = API_URL + '/audio/remove';
  private static DETECT_LANG_URL = API_URL + '/audio/deletectLang';


  public static async fetch(audioId: number, groupId: number): Promise<IRestAudioGroup | null> {
    const dispatch = getDispatch();
    const url = `${AudioRest.FETCH_URL}/${groupId}/${audioId}`;
    return await dispatch(getRequest(url));
  }

  public static async getCaptions(audioId: number): Promise<IRestAudioCaption[] | null> {
    const dispatch = getDispatch();
    const url = `${AudioRest.GET_CAPTIONS_URL}/${audioId}`;
    return await dispatch(getRequest(url));
  }

  public static async addAudio(text: string, groupId: number, langCode?: string): Promise<TAudio> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(AudioRest.ADD_URL, {text, groupId, langCode}));
  }

  public static async removeAudio(audioId: number, groupId: number): Promise<any> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(AudioRest.REMOVE_URL, {audioId, groupId}));
  }

  public static async detectLang(text: string): Promise<IRestAudioLangDetectResult> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(AudioRest.DETECT_LANG_URL, {text}));
  }

}
