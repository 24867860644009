import React from 'react';
import { IDashboardProps } from './types';
import { theme } from '../../../../../common/styles/theme';
import { HistoryProrvider } from '../../../../effects/historyProrvider';
import { Route, Router, Switch } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { PhrasesColumnHOC } from '../../Vocabulary/PhraseListDetailsColumn/hocs/PhrasesColumnHOC';
import { VideoHOC } from '../../Video/hocs/VideoHOC';
import { ButtonHelp } from './ButtonHelp';
import { AudioHOC } from '../../Audio/hocs/AudioHOC';
import { TopBarPanelHOC } from '../../ToolbarPanel/Toolbar/hocs/TopBarPanelHOC';
import { DeviceContext, MIN_WIDTH } from '../../../../../common/contexts/DeviceContext';
import { NoVideoMobileHOC } from '../../NoVideoMobile/hocs/NoVideoMobileHOC';
import { OneTimeTooltipSaveForMagicHOC } from '../../OneTimeTooltip/hocs/OneTimeTooltipSaveForMagicHOC/OneTimeTooltipSaveForMagicHOC';
import { DailyGoalHOC } from '../../ToolbarPanel/DailyGoal/hocs/DailyGoalHOC';
import { MenuButton } from '../../ToolbarPanel/Menu/components/MenuButton/MenuButton';
import { MenuHOC } from '../../ToolbarPanel/Menu/hocs/MenuHOC';
import { OneTimeTooltipTutorialHOC } from '../../OneTimeTooltip/hocs/OneTimeTooltipTutorialHOC/OneTimeTooltipTutorialHOC';

export const Dashboard: React.FC<IDashboardProps> = ({
  visible,
  isEmpty,
  onShowHelp,
}) => {
  const {isFullScreen, isMobile, isSmall} = React.useContext(DeviceContext);
  // if (!visible && !isMobile) return null;

  const history = HistoryProrvider.getHistory();

  // const [canShowOnboarding, setCanShowOnboarding] = React.useState(false);
  // const onboardingTimeout = React.useRef(null as any);
  // React.useEffect(() => {
  // 	if (onboardingTimeout.current) clearTimeout(onboardingTimeout.current);
  // 	if (showOnboardingStep !== null) {
  // 		onboardingTimeout.current = setTimeout(() => {
  // 			onShowOnboarding(showOnboardingStep);
  // 			setCanShowOnboarding(true);
  // 		}, 3000);
  // 	}

  // 	return () => {
  // 		if (onboardingTimeout.current) clearTimeout(onboardingTimeout.current);
  // 	}
  // }, [showOnboardingStep]);

  return (
    <Router history={history}>
      {isMobile ? (
        <Stack
          direction='column'
          sx={{
            width: '100%',
            height: 1,
            background: theme.palette.secondary.main,
            display: visible ? 'flex' : 'none',
          }}
        >
          <TopBarPanelHOC
            isVideo={isMobile}
          />
          <Stack
            direction='column'
            gap={theme.spacing(2)}
            sx={{
              flex: `1 1 100%`,
              minHeight: 0,
              width: '100%',
              p: theme.spacing(1),
            }}
          >
            <Switch>
              <Route exact path='/video/:groupId/:videoId/:phraseId?/:pause?'>
                <VideoHOC />
              </Route>
              {visible &&
                <NoVideoMobileHOC />
              }
            </Switch>
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction='row'
          gap={theme.spacing(2)}
          sx={{
            minWidth: MIN_WIDTH + 'px',
            width: 1,
            height: 1,
            overflowX: 'auto',
            overflowY: 'hidden',
            p: theme.spacing(2),
            pl: theme.spacing(5),
            background: theme.palette.secondary.main,
            justifyContent: 'flex-start',

            '.root-inner.fullscreen &': {
              minWidth: 0,
              p: 0,
              overflowX: 'hidden',
            },
          }}
        >
          {!isEmpty && <>
              <Box
                sx={{
                  position: 'fixed',
                  zIndex: 500,
                  left: theme.spacing(.5),
                  top: theme.spacing(2),
                  m: `0 !important`,

                  '.menu__button': {
                    width: theme.spacing(4),
                    height: theme.spacing(4),
                    color: theme.palette.grey[400],
                    background: theme.palette.common.white + ' !important',
                    borderRadius: '50%',
                    boxShadow: theme.shadows[8],

                    '.MuiSvgIcon-root': {
                      width: theme.spacing(2.5),
                      height: theme.spacing(2.5),
                    },
                  },

                  '.PrivateSwipeArea-root': {
                    width: theme.spacing(.5) + ' !important',
                  },
                }}
              >
                <MenuButton />
              </Box>
              <ButtonHelp onClick={onShowHelp} />
              <OneTimeTooltipTutorialHOC />
              <DailyGoalHOC isSmall />
          </>}

          <Switch>
            <Route exact path='/video/:groupId/:videoId/:phraseId?/:pause?'>
              <VideoHOC />
            </Route>
            <Route exact path='/audio/:groupId/:audioId'>
              <AudioHOC />
            </Route>
            <Route>
            <Box
              sx={{
                ml: theme.spacing(-3),
                '& > .menu': {
                  minWidth: theme.spacing(30),
                },
              }}
            >
              <MenuHOC />
            </Box>
            </Route>
          </Switch>

          <Switch>
            <Route
              exact
              path={['/video/:groupId/:videoId/:phraseId?/:pause?', '/audio/:groupId/:audioId']}
            >
              <PhrasesColumnHOC />
            </Route>
          </Switch>
        </Stack>
      )}
      <OneTimeTooltipSaveForMagicHOC />
    </Router>
  );
};

