import { getState } from '../../../spa/store';
import { getAppSettings, getAuthUser } from '../../../spa/store/general/selectors';
import axios, { AxiosResponse } from 'axios';

export enum ELogRecordQueueType {
  TRANSLATION,
  PROMPT_ACCESS,
  PHRASE_SAVE,
  VIDEO_WATCH
}

export type TStatLogBody = {
  type: ELogRecordQueueType,
  value?: number,
  langCode?: string
}

export type TLangCodeValueMap = Record<string, number>

export type TStatsDateRangeResponse = {
  dateRangeId: string,
  translation: TLangCodeValueMap,
  phrase: TLangCodeValueMap,
  prompt: TLangCodeValueMap,
  videoWatch: TLangCodeValueMap
}


export type TStatsResponse = {
  resultList: TStatsDateRangeResponse[]
}

type TStatVideoWatchTotalResponse = {
  total: number
}

export type TStatDateRange = {
  id: string,
  fromDate: string,
  toDate: string
}

export class StatRest {

  private static LOG_URL = 'log/save';
  private static STAT_URL = 'stat';
  private static STAT_VIDEO_WATCH_TOTAL_URL = 'stat/videoWatchTotal';

  public static async sendLog(stat: TStatLogBody) {
    await this.post(StatRest.LOG_URL, stat);
  }

  public static async getStat(dateRangeList: TStatDateRange[]): Promise<TStatsResponse> {
    return this.post(StatRest.STAT_URL, {dateRangeList, version: 1});
  }

  public static async getStatVideoWatchTotal(): Promise<number> {
    const result: TStatVideoWatchTotalResponse = await this.post(StatRest.STAT_VIDEO_WATCH_TOTAL_URL, {});
    return result?.total || 0;
  }

  private static post(url: string, body: any = {}) {
    const state = getState();
    const baseUrl = getAppSettings(state).statServersUrl?.trim();
    if (!baseUrl) return;

    const authUser = getAuthUser(state);
    if (!authUser || !authUser.googleId) {
      return Promise.reject('unauth user');
    }

    const config = {
      headers: { authorization: authUser.token }
    };
    return axios.post(baseUrl + url, body, config)
      .then((response: AxiosResponse) => response.data);
  }
}