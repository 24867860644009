import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { AudioLibraryGroupItem } from '../components/AudioLibraryGroupItem';
import { IAudioLibraryGroupItemEvents, IAudioLibraryGroupItemFields } from '../components/types';
import { IAudioLibraryGroupItemHOCOwnProps } from './types';
import { AudioLibraryGroupItemEvents } from './events';
import { getAudioByGroupIdAndId, getGroupTargetLanguage, getUserGroupById } from '../../../../../store/models/selectors';
import { getAuthUser } from '../../../../../store/general/selectors';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    {audioId, groupId, isOpened}: IAudioLibraryGroupItemHOCOwnProps
  ): IAudioLibraryGroupItemFields | null => {
    const audio = getAudioByGroupIdAndId(state, groupId, audioId);
    const title = audio && new Date(audio.createdAt).toLocaleString() || '';
    const user = getAuthUser(state);
    const author = user?.publicProfile?.name || user?.name || '';
    const group = getUserGroupById(state, groupId);
    const gropuLang = group && getGroupTargetLanguage(state, group);
    const langCodes = gropuLang ? [gropuLang.code] : null as any;

    return {
      author,
      isOpened,
      title,
      langCodes,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {audioId, groupId}: IAudioLibraryGroupItemHOCOwnProps
): IAudioLibraryGroupItemEvents => ({
    onClick: () => dispatch(AudioLibraryGroupItemEvents.onClick(audioId, groupId)),
  }
);

export const AudioLibraryGroupItemHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioLibraryGroupItem);

AudioLibraryGroupItemHOC.displayName = 'AudioLibraryGroupItemHOC';