import { getAutoPause, getPlayerState } from '../../../../store/general/selectors';
import { getState } from '../../../../store';
import { Pause } from '../../../../types/pause-constants';
import { PlayerManager } from '../playerManager';
import { EPlayerControllerMode, PlayerController } from '../playerController';
import { getTargetCaptions } from '../../../../store/videos/selectors';
import { getActiveUserGroup, getUserGroupLangKnown, getUserGroupLangToLearn } from '../../../../store/models/selectors';
import { PhraseDetailsTranslateManager } from '../../../phrase-details/translate/phraseDetailsTranslateManager';
import { textSpeaker, TextSpeaker } from '../../../textSpeaker';
import { PlayerStates } from '../../../../types/common';
import { getCurrentMovieKey } from '../../../../store/current-video/selectors';
import { TimeUtils } from '../../../../../common/utils/time-utils';


export abstract class PlayCaptionBaseHandler {

  protected async handleTargetIndexChange(index: number, disablePlayTranslate: boolean) {
    const playTranslation = false; // tmp disabled
    if (!disablePlayTranslate && playTranslation && index > 0) {
      return this.startPlayTranslate(index - 1);
    }
    const pause = getAutoPause(getState());
    if (pause === Pause.NoPauseValue) return;
    PlayerManager.getInstance().pausePlay();
    if (pause !== Pause.FullStopValue) {
      PlayerController.getInstance().startWaitTimer(pause * 1000);
    }
  }

  protected abstract getHandlerPlayMode(): EPlayerControllerMode;

  private async startPlayTranslate(index: number) {
    PlayerManager.getInstance().pausePlay();
    const state = getState();
    const captions = getTargetCaptions(state);
    const text = captions[index]?.text;
    const group = getActiveUserGroup(state);
    const videoId = getCurrentMovieKey(state);
    const nativeLng = getUserGroupLangKnown(state, group);
    const targetLng = getUserGroupLangToLearn(state, group);
    if (text) {
      const translateText = await TextTranslator.translate({
        text,
        groupId: group.id,
        videoId,
        fromLang: targetLng.code,
        toLang: nativeLng.code,
        captionIndex: index
      });

      await TimeUtils.pause(500); // wait pause player
      const playMode = PlayerController.getInstance().getMode();

      if (playMode !== this.getHandlerPlayMode() || getPlayerState(getState()) === PlayerStates.PLAYING) return;

      await this.playTranslate(translateText, nativeLng.code);
      PlayerController.getInstance().resumeAfterPauseFinish();
    }
  }

  private playTranslate(text: string, langCode: string): Promise<any> {
    return new Promise(resolve => {
      let playTranslate = true;
      TextSpeaker.playText(text, langCode, () => {
        playTranslate = false;
      })

      const timer = setInterval(() => {
        const playing = getPlayerState(getState()) === PlayerStates.PLAYING;
        if (playing || !playTranslate) {
          if (playing && playTranslate) {
            textSpeaker.stop();
          }
          clearInterval(timer)
          resolve(1);
        }
      }, 500)
    })

  }
}

class TextTranslator {

  private static MAX_CACHE_SIZE = 1000;
  private static cache: Record<string, string> = {};

  public static async translate(params: {groupId: number, videoId: string, captionIndex: number, fromLang: string, toLang: string, text: string}): Promise<string> {
    const {groupId, videoId, captionIndex, fromLang, toLang, text} = params;
    const key = groupId + '-' + videoId + '-' + captionIndex + '-' + fromLang + '-' + toLang;
    if (this.cache[key]) {
      return this.cache[key];
    }
    if (Object.keys(this.cache).length > this.MAX_CACHE_SIZE) {
      this.cache = {};
    }
    const result = await PhraseDetailsTranslateManager.translate(
      {code: fromLang, name: ''},
      {code: toLang, name: ''},
      text);
    this.cache[key] = result;
    return result;
  }
}