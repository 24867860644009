import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IPhraseTranslationEvents, IPhraseTranslationFields } from '../components/types';
import { PhraseTranslation } from '../components/PhraseTranslation';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseTranslationEvents } from './events';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { getAuthUser, getOneTimeTooltip } from '../../../../../store/general/selectors';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseTranslationFields => {
    const activeTab = PhraseDetailsSelectors.getActiveTab(state);
    const active = !!(activeTab && activeTab.type !== EPhraseDetailsTabType.NOTES);
    const text = PhraseDetailsSelectors.getSelectText(state);
    const translateResults = PhraseDetailsSelectors.getTranslateResultText(state) || undefined;
    const processing = PhraseDetailsSelectors.getTranslateResultProcessing(state) || false;
    const {translateLangs} = getAuthUser(state);
    return {
      active,
      text,
      translateResults,
      processing,
      translateLangs,
      isTranslateContext: PhraseContextEditorSelectors.getIsTranslateContext(state),
      showTranslationsHint: getOneTimeTooltip(state).showTranslations,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseTranslationEvents => ({
    onLoad: () => dispatch(PhraseTranslationEvents.onLoad()),
    onRefresh: langCode => dispatch(PhraseTranslationEvents.onRefresh(langCode)),
    onCopyNote: () => dispatch(PhraseTranslationEvents.onCopyNote()),
    onHideTranslationsHint: () => dispatch(PhraseTranslationEvents.onHideTranslationsHint()),
  }
);

export const PhraseTranslationHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseTranslation);

PhraseTranslationHOC.displayName = 'PhraseTranslationHOC';
