import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { TextSelectionPopup } from '../component/TextSelectionPopup';
import { ITextSelectionPopupEvents, ITextSelectionPopupFields } from '../component/types';
import { TextSelectionPopupEvents } from './events';
import { CaptionsSelectionPopupSelectors } from '../../../../store/captions-selection-popup/selectors';
import { getMainViewMode, getPlayerState } from '../../../../store/general/selectors';
import { PlayerStates } from '../../../../types/common';
import { EMainViewMode } from '../../../../store/general/types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): ITextSelectionPopupFields => {
    const playMode = getPlayerState(state) === PlayerStates.PLAYING &&
      !CaptionsSelectionPopupSelectors.getPlayingSelectText(state);

    const isActive = CaptionsSelectionPopupSelectors.isShow(state) &&
      getMainViewMode(state) !== EMainViewMode.PHRASE_DETAILS; // mobile
    return {
      actionButton: CaptionsSelectionPopupSelectors.getActionButton(state),
      selectResult: CaptionsSelectionPopupSelectors.getSelectResult(state),
      selection: CaptionsSelectionPopupSelectors.getSelection(state),
      isActive,
      autoSpeechSelectText: CaptionsSelectionPopupSelectors.getAutoSpeechText(state),
      playMode
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): ITextSelectionPopupEvents => ({
    onDismiss: unboundClick => dispatch(TextSelectionPopupEvents.onDismiss(unboundClick)),
    onSavePhrase: selectResult => dispatch(TextSelectionPopupEvents.onSavePhrase(selectResult)),
    onPreviewPhrase: (selectResult, translate) => dispatch(TextSelectionPopupEvents.onPreviewPhrase(selectResult, translate)),
    onSelectText: selectResult => dispatch(TextSelectionPopupEvents.onSelectText(selectResult)),
    onCheckCanSelect: selectResult => dispatch(TextSelectionPopupEvents.onCheckCanSelect(selectResult)),
    onMouseEnter: () => dispatch(TextSelectionPopupEvents.onMouseEnter()),
    onMouseLeave: () => dispatch(TextSelectionPopupEvents.onMouseLeave()),
    onSpeechText: stopOnPlay => dispatch(TextSelectionPopupEvents.onSpeechText(stopOnPlay)),
    onPlay: () => dispatch(TextSelectionPopupEvents.onPlay()),
    onUpdateActionButton: actionButton => dispatch(TextSelectionPopupEvents.onUpdateActionButton(actionButton)),
    onClose: () => dispatch(TextSelectionPopupEvents.onClose()),
    onReplay: () =>  dispatch(TextSelectionPopupEvents.onReplay()),
    onStartPlay: () => dispatch(TextSelectionPopupEvents.onStartPlay()),
    onCopyText: () => dispatch(TextSelectionPopupEvents.onCopyText()),
    onOpenPrompts: () => dispatch(TextSelectionPopupEvents.onOpenPrompts()),
    onSaveNote: () => dispatch(TextSelectionPopupEvents.onSaveNote()),
    onEscape: () => dispatch(TextSelectionPopupEvents.onEscape()),
  }
);

export const TextSelectionPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(TextSelectionPopup);

TextSelectionPopupHOC.displayName = 'TextSelectionPopupHOC';
