import { EPlayerControllerMode, PlayerController } from '../playerController';
import { PlayerManager } from '../playerManager';
import { PlayCaptionBaseHandler } from './playCaptionBaseHandler';


export class PlayCommonHandler extends PlayCaptionBaseHandler {

  private lastStartPlayTime: number;

  public onStarPlay() {
    this.lastStartPlayTime = new Date().getTime();
  }

  public async play(time?: number) {
    const playerController = PlayerController.getInstance();
    playerController.reset();
    playerController.setMode(EPlayerControllerMode.PLAY_COMMON);
    await PlayerManager.getInstance().startPlay(time);
  }

  public async onTargetIndexChange(index: number): void {
    const lastStartPeriod = (new Date().getTime() - this.lastStartPlayTime);
    this.handleTargetIndexChange(index, lastStartPeriod < 500);
  }
  
  public onNativePlayClicked() {
    const playerController = PlayerController.getInstance();
    playerController.reset();
    playerController.setMode(EPlayerControllerMode.PLAY_COMMON);
    PlayerManager.getInstance().onNativePlayClicked();
  }

  protected getHandlerPlayMode(): EPlayerControllerMode {
    return EPlayerControllerMode.PLAY_COMMON;
  }


}