import React from 'react';
import { Stack, SxProps, } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { IContentLibraryHeaderProps } from './types';
import { Tabs } from '../../../../../../common/components/Tabs/Tabs';
import { Tab } from '../../../../../../common/components/Tab/Tab';
import { ListIcon } from '../../../../../../../icons/ListIcon';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { VideoItemIcon } from '../../../../../../../icons/VideoItemIcon';
import { EMainViewMode } from '../../../../../store/general/types';
import { ContentLibraryFiltersHOC } from '../../ContentLibraryFilters/hocs/ContentLibraryFiltersHOC';

export function ContentLibraryHeader({
	viewMode,
	mainViewMode,
	onChangeViewMode,
}: IContentLibraryHeaderProps) {

	const className = 'content-library-header';

	const {isMobile, isSmall} = React.useContext(DeviceContext);

	const tabsSx: SxProps = {
		p: 0,
		overflow: 'visible',

		'.MuiTabs-flexContainer': {
			gap: theme.spacing(.75),
		},

		'.MuiTab-root': {
			minWidth: theme.spacing(5),
			minHeight: theme.spacing(5),
			padding: theme.spacing(0, 1),

			'.MuiSvgIcon-root': {
				opacity: .6,
			},

			'&.Mui-selected': {
				'.MuiSvgIcon-root': {
					color: theme.palette.primary.main,
					opacity: 1,
				}
			},

			'.mobile &': {
				minHeight: theme.spacing(4),
			},

			[theme.breakpoints.down('md')]: {
				// minWidth: theme.spacing(4),
				// maxWidth: theme.spacing(4),
				// pl: theme.spacing(.75),
				// justifyContent: 'flex-start',
			},
		},
	};

	return (
		<Stack
			className={`${className}__stack`}
			direction={'row'}
			justifyContent={'space-between'}
			alignItems={'center'}
			gap={2}
			sx={{
				width: 1,
			}}
		>	
			<Tabs
				sx={{...tabsSx,

					'@media (max-width: 720px)': {
						'.MuiTab-root': {
							minWidth: theme.spacing(4),
							maxWidth: theme.spacing(4),
							pl: theme.spacing(.75),
							justifyContent: 'flex-start',
						},
					}
				}}
				value={viewMode}
				onChange={(e, value) => {
					onChangeViewMode(value);
				}}
			>
				<Tab
					value={EContentLibViewMode.VIDEO_LIST}
					icon={<VideoItemIcon />}
					iconPosition={'start'}
					label={'Videos'}
				/>
				<Tab
					value={EContentLibViewMode.GROUP_LIST}
					icon={<ListIcon />}
					iconPosition={'start'}
					label={'Playlists'}
				/>
			</Tabs>

			{mainViewMode === EMainViewMode.CONTENT_LIBRARY &&
				<ContentLibraryFiltersHOC />
			}

		</Stack>
	);
};
