import * as React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Box, CircularProgress, IconButton, Stack, Tab, Tabs } from '@mui/material';
import { IStatisitcsProps, TStatsResultList } from './types';
import { StatisitcsItem } from './StatisticsItem';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { ButtonIconGrey } from '../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';
import { LogoLabel } from '../../../../../../../icons/LogoLabel';
import { LangFlag } from '../../../LangFlag/LangFlag';
import { Modal } from '../../../../../../common/components/Modal/Modal';

export const Statisitcs: React.FC<IStatisitcsProps> = ({
	show,
	onLoad,
	onClickBack,
	onClickLogo,
}) => {
	if (!show) return null;

	const className = 'statisitcs-page';
	const codeAll = 'ALL';

	const [progress, setProgress] = React.useState<boolean>(false);
	const [result, setResult] = React.useState<TStatsResultList>([]);
	const [data, setData] = React.useState<any>(null);
	const [width, setWidth] = React.useState(500);
	const [codes, setCodes] = React.useState<string[]>([]);
	const [code, setCode] = React.useState<string>(codeAll);

	const ref = React.useRef(null);

	function getValue (map) {
		if (code === codeAll) {
			let arr = Object.values(map);
			let values = arr && arr.length && arr.reduce((values: number, i) => {
				return values + Number(i);
			}, 0);
			return values || 0;
		} else {
			return Number(map[code]);
		}
	}

	React.useEffect(() => {
		const data = result.map(i => {
			let date = new Date(i.dateRangeId);
			return {
				x: date.toLocaleString('default', { day: '2-digit', month: 'numeric' }),
				Min: Math.floor(getValue(i.videoWatch) / 60),
				Translations: getValue(i.translation),
				Saved: getValue(i.phrase),
				Prompts: getValue(i.prompt),
			}
		});
		setData(data);

		if (ref.current) {
			setWidth(ref.current.clientWidth);
		}
	}, [code, result]);

	React.useEffect(() => {
		
	}, [result]);

	React.useEffect(() => {
		setProgress(true);
		onLoad().then((result) => {
			setProgress(false);
			setResult(result.reverse());
			let codes = new Set<string>();
			result.forEach(i => {
				[
					i.phrase,
					i.prompt,
					i.translation,
					i.videoWatch,
				].forEach(obj => {
					for (let k in obj) if (k && !codes.has(k)) codes.add(k);
				});
			});
			setCodes([codeAll, ...codes]);
		}).catch((e) => {
			setProgress(false);
			console.log('error', e);
		})
	}, []);

	return <Modal
		open={show}
		isCloseButton={false}
		onClose={onClickBack}
		PaperProps={{
			sx: {
				position: 'fixed',
				top: 0,
				left: 0,
				width: `100vw`,
				height: `100vh`,
				maxHeight: `100vh !important`,
				background: theme.palette.secondary.main,
				borderRadius: 0,

				'& > .MuiStack-root': {
					gap: `0 !important`,
					p: `0 !important`,
					maxHeight: `100% !important`,
					height: 1,
				},
			},
		}}
	>
	<Stack
		className={`
			${className}
		`}
		sx={{
			width: 1,
			height: 1,
			alignItems: 'center',
			bgcolor: theme.palette.secondary.main,
		}}
	>
		<Paper
			className={className}
			sx={{
				position: 'relative',
				zIndex: 100,
				flex: `0 0 ${theme.spacing(8)}`,
				width: 1,
				py: 1.5,
				px: 2,
				borderRadius: 0,
				boxShadow: theme.shadows[8],

				'.mobile &': {
					p: 1,
				}
			}}
		>
			<Stack
				direction={'row'}
				alignItems={'center'}
				gap={theme.spacing(2)}
				sx={{
					width: 1,
					height: 1,

					'.mobile &': {
						gap: 1,
						justifyContent: 'space-between',
					}
				}}
			>
				<ButtonIconGrey
					sx={{
						'.MuiSvgIcon-root': {
							width: theme.spacing(3),
							height: theme.spacing(3),
						},
					}}
					onClick={onClickBack}
				>
					<ArrowLightLeftIcon />
				</ButtonIconGrey>
				
				<IconButton
					sx={{ p: 0, }} 
					onClick={onClickLogo}
				>
					<LogoLabel sx={{ height: theme.spacing(4), width: 'auto' }} />
				</IconButton>

			</Stack>
		</Paper>

		<Stack
			sx={{
				flex: `1 1 100%`,
				minHeight: 0,
				width: 1,
				alignItems: 'center',
				p: 2,

				overflow: 'auto',
			}}
		>
			<Paper
				sx={{
					width: 1,
					p: 3,
					fontSize: theme.typography.subtitle2,
					borderRadius: theme.spacing(1.25),
					boxShadow: theme.shadows[2],
				}}
			>
				<Stack
					flexDirection={'row'}
					gap={1}
				>
					
				</Stack>

				<Tabs
					value={codes.indexOf(code)}
					onChange={(e, value) => setCode(codes[value])}
					sx={{
						mt: theme.spacing(-2),
						mb: theme.spacing(2),

						'.MuiTab-root': {
							p: `0 ${theme.spacing(1)}`,
							minWidth: theme.spacing(8),
						},
					}}
				>
					{codes.map(i => {
						return <Tab
							key={i}
							label={<LangFlag code={i} showCode />}
						/>
					})}
				</Tabs>

				<Stack
					gap={3}
					ref={ref}
				>
					<StatisitcsItem
						title={'Translations accessed'}
						dataKey={'Translations'}
						data={data}
						width={width}
					/>
					<StatisitcsItem
						title={'Saved phrases'}
						dataKey={'Saved'}
						data={data}
						width={width}
					/>
					<StatisitcsItem
						title={'Prompts accessed'}
						dataKey={'Prompts'}
						data={data}
						width={width}
					/>
					<StatisitcsItem
						title={'Video watch time (minutes)'}
						dataKey={'Min'}
						data={data}
						width={width}
					/>

				</Stack>

				{progress &&
					<Box
						sx={{
							position: 'absolute',
							left: '50%',
							top: '50%',
							transform: 'translate(-50%, -50%)',
						}}
					>
						<CircularProgress
							size={theme.spacing(3)}
						/>
					</Box>
				}
				
			</Paper>
		</Stack>
	</Stack>
	</Modal>
};