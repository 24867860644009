import { ICaptionsItem } from '../../../../types/common';
import { PlayerManager } from '../playerManager';
import { getTargetCaptions } from '../../../../store/videos/selectors';
import { getState } from '../../../../store';
import { EPlayerControllerMode, PlayerController } from '../playerController';
import { PlayCaptionBaseHandler } from './playCaptionBaseHandler';

export class PlayCaptionHandler extends PlayCaptionBaseHandler {

  private startCaptionIndex: number;

  public async play(caption: ICaptionsItem) {
    const playerController = PlayerController.getInstance();
    const playerManager = PlayerManager.getInstance()
    playerController.reset();

    await playerManager.pausePlay();

    const captions = getTargetCaptions(getState());
    this.startCaptionIndex = this.getCaptionIndex(captions, caption);
    playerController.setMode(EPlayerControllerMode.PLAY_CAPTION);
    await playerManager.startPlay(caption.startTime);
  }

  public onTargetIndexChange(index: number): void {
    if (this.startCaptionIndex === index) return;
    this.handleTargetIndexChange(index, false);
  }


  private getCaptionIndex(captions: ICaptionsItem[], caption: ICaptionsItem) {
    return captions.findIndex(c => c.startTime === caption.startTime);
  }

  protected getHandlerPlayMode(): EPlayerControllerMode {
    return EPlayerControllerMode.PLAY_CAPTION;
  }

}