import { IState } from '../../../../../store/types';
import { getMainLayoutPhrasesColumn } from '../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { PhrasesColumn } from '../components/PhrasesColumn';
import { IPhrasesColumnEvents, IPhrasesColumnFields } from '../components/types';
import { PhrasesColumnEvents } from './events';
import { PhraseDetailsPanelSelectors } from '../../../PhraseDetails/selectors';
import { getVideoTeacherUserActiveId } from '../../../../../store/videos/selectors';
import { PhraseListSelectors } from '../../../../../store/phrase-list/selectors';
import { EVocabularyPhraseType } from '../../../../../types/common';


const mapStateToProps = (
	state: IState
): IPhrasesColumnFields => {
	const phrasesColumn = getMainLayoutPhrasesColumn(state);
	const text = PhraseDetailsPanelSelectors.getText(state);

	const teacherActiveId = getVideoTeacherUserActiveId(state);
    let savedCount = teacherActiveId
      ? 0
      : PhraseListSelectors.findPhrasesByTypes(state, [
        EVocabularyPhraseType.DEFAULT,
        EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED,
        EVocabularyPhraseType.LESSON
      ])
        .filter(phrase => phrase.fullPhrase?.trim()?.length)
        .length;

	return {
		phrasesColumn,
		text,
		savedCount,
	}
};

const mapDispatchToProps = (
	dispatch: any
): IPhrasesColumnEvents => {
	return {
		setType: (type) => dispatch(PhrasesColumnEvents.setType(type)),
	}
};

export const PhrasesColumnHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(PhrasesColumn);

PhrasesColumnHOC.displayName = 'PhrasesColumnHOC';
