import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { getMainLayoutPhrasesColumn, getOneTimeTooltip } from '../../../../../store/general/selectors';
import { OneTimeTooltipFullscreen } from '../../components/OneTimeTooltipFullscreen/OneTimeTooltipFullscreen';
import { IOneTimeTooltipFullscreenEvents, IOneTimeTooltipFullscreenFields } from '../../components/OneTimeTooltipFullscreen/types';
import { PhraseDetailsPanelSelectors } from '../../../PhraseDetails/selectors';
import { getVideoTeacherUserActiveId } from '../../../../../store/videos/selectors';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { EPhrasesColumnType } from '../../../../../store/general/types';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IOneTimeTooltipFullscreenFields => {
		const oneTimeTooltips = getOneTimeTooltip(state);
		let show = oneTimeTooltips.showFullscreen;
		if (show) {
			const phrasesColumn = getMainLayoutPhrasesColumn(state);
			show = phrasesColumn !== EPhrasesColumnType.HIDE;
		}
		if (show) {
			const text = PhraseDetailsPanelSelectors.getText(state);
			if (text) {
				show = false;
			} else {
				const teacherId = getVideoTeacherUserActiveId(state);
				if (teacherId > 0) {
					show = false;
				} else {
					const activeTab = PhraseDetailsSelectors.getActiveTab(state);
					if (activeTab && activeTab.type === EPhraseDetailsTabType.NOTES) {
						show = false;
					}
				}
			}
		}
		
		return {
			show,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IOneTimeTooltipFullscreenEvents => ({
});

export const OneTimeTooltipFullscreenHOC = connect(
	mapStateToProps,
	null,
)(OneTimeTooltipFullscreen);

OneTimeTooltipFullscreenHOC.displayName = 'OneTimeTooltipFullscreenHOC';
