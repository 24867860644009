import { AppThunk } from '../../../../../store/types';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';
import { setAuthUserAction, setMainViewMode, setPublicPageAction } from '../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../store/general/types';
import { AuthManager } from '../../../../../../common/utils/auth-manager';
import { updateUserLanguages } from '../../../../../effects/updateUserLanguages';
import { updateUserGroups } from '../../../../../effects/updateUserGroups';
import { getAuthUser } from '../../../../../store/general/selectors';
import { restoreAppSettings } from '../../../../../effects/prepareAfterOpen';
import { VideoTutorialEffects } from '../../../../../effects/videoTutorialEffects';
import { PhraseDetailsTabEffects } from '../../../../../effects/phraseDetailsTabEffects';

export class ContentLibraryDashboardEvents {

  public static onLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (!getAuthUser(getState())) { // url
        dispatch(setPublicPageAction(true));
        const result = await AuthManager.autoLogin();
        if (result && result.authUser) {
          restoreAppSettings(result.appSettings);
          dispatch(setAuthUserAction(result.authUser));
          dispatch(updateUserLanguages());
          dispatch(updateUserGroups(true));
          PhraseDetailsTabEffects.load();
          VideoTutorialEffects.loadList();
        }
        dispatch(setMainViewMode(EMainViewMode.CONTENT_LIBRARY));
      }

      await ContentLibEffects.loadLangs();
    }
  }

  public static onUnLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setPublicPageAction(false));
      dispatch(setMainViewMode(EMainViewMode.DASHBOARD));
    }
  }


}