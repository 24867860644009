import { AppThunk } from '../../../../../store/types';
import { Navigator } from '../../../../../effects/navigator';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { getOnboarding } from '../../../../../store/general/selectors';
import { setOnboardingAction } from '../../../../../store/general/actions';

export class VideoLibraryGroupItemEvents {

  public static onClick (
    videoId: string,
    userGroupId: number
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().resetMode();
      Navigator.openVideo(userGroupId, videoId);

      const onboarding = getOnboarding(getState());
      if (onboarding && onboarding.isActive && !onboarding.step) {
        dispatch(setOnboardingAction({ step: 1 }))
      }
    }
  }

}
