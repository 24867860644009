import { PlayerFactory } from '../player-factory';
import { IYoutubePlayer } from '../../../types/common';

export interface IPlayerProvider {
  play(): void;
  getCurrentTime(): Promise<number>;
  pause(): void;
}

export class VideoPlayerProvider implements IPlayerProvider {

  public async play(time?: number) {
    const playerApi = await PlayerFactory.getPlayerApi();
    if (time !== undefined) {
      await playerApi.seekVideoTo(time);
    }
    await playerApi.playVideo();
  }

  public async getCurrentTime(): Promise<number> {
    const playerApi = await PlayerFactory.getPlayerApi();
    return await playerApi.player.getCurrentTime();
  }

  public async pause() {
     const playerApi = await PlayerFactory.getPlayerApi();
     await playerApi.pauseVideo();
  }

  public static async getPlayer(): Promise<IYoutubePlayer> {
    const playerApi = await PlayerFactory.getPlayerApi();
    return playerApi.player;
  }


}

