import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { OneTimeTooltipSaveForMagicEvents } from './events';
import { EOneTimeTooltipType, OneTimeTooltipTextProvider } from '../oneTimeTooltipTextProvider';
import { getConfirmEmailStatus, getMainViewMode, getOneTimeTooltip } from '../../../../../store/general/selectors';
import { EAuthUserConfirmEmailStatus, EMainViewMode } from '../../../../../store/general/types';
import { OneTimeTooltipSaveForMagic } from '../../components/OneTimeTooltipSaveForMagic/OneTimeTooltipSaveForMagic';
import {
	IOneTimeTooltipSaveForMagicEvents,
	IOneTimeTooltipSaveForMagicFields
} from '../../components/OneTimeTooltipSaveForMagic/types';
import { getUserPhraseCountByUserTargetLang } from '../../../../../store/models/selectors';
import { SAVE_FOR_MAGIC_NUMBER } from '../../../../../../common/constants';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IOneTimeTooltipSaveForMagicFields => {
		const oneTimeTooltips = getOneTimeTooltip(state);
		let text = '';
		let show = getConfirmEmailStatus(state) !== EAuthUserConfirmEmailStatus.DONE &&
			(oneTimeTooltips.showSaveForMagicTooltip || oneTimeTooltips.showSavedForMagicTooltip) &&
			// !oneTimeTooltips.showNativeAvailableTooltip &&
			!oneTimeTooltips.showTutorialTooltip &&
			!oneTimeTooltips.showTutorialPopup &&
			getMainViewMode(state) === EMainViewMode.DASHBOARD;
		
		let isDone;

		if (show) {
			const saved = getUserPhraseCountByUserTargetLang(state);
			isDone = saved >= SAVE_FOR_MAGIC_NUMBER;

			if (oneTimeTooltips.showSavedForMagicTooltip && !isDone) {
				show = false;
			}

			const left = SAVE_FOR_MAGIC_NUMBER - saved;
			text = isDone
				? OneTimeTooltipTextProvider.getText(EOneTimeTooltipType.SAVED_FOR_MAGIC, [
					String(SAVE_FOR_MAGIC_NUMBER),
				])
				: OneTimeTooltipTextProvider.getText(EOneTimeTooltipType.SAVE_FOR_MAGIC, [
					String(SAVE_FOR_MAGIC_NUMBER),
					String(saved),
					String(left),
				]);
		}

		return {
			show,
			text,
			isDone,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IOneTimeTooltipSaveForMagicEvents => ({
	onClose: () => dispatch(OneTimeTooltipSaveForMagicEvents.onClose()),
	onClickLink: (e) => dispatch(OneTimeTooltipSaveForMagicEvents.onClickLink(e)),
	onShowDone: () => dispatch(OneTimeTooltipSaveForMagicEvents.onShowDone()),
});

export const OneTimeTooltipSaveForMagicHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(OneTimeTooltipSaveForMagic);

OneTimeTooltipSaveForMagicHOC.displayName = 'OneTimeTooltipSaveForMagicHOC';
