import { IState } from '../../../../../../store/types';
import { connect } from 'react-redux';
import { EditPlaylistItem } from '../../components/EditPlaylistItem/EditPlaylistItem';
import { IEditPlaylistItemEvents, IEditPlaylistItemFields } from '../../components/EditPlaylistItem/types';
import { IEditPlaylistItemHOCOwnProps } from './types';
import { EditPlaylistItemEvents } from './events';
import {
  getLibraryGroup,
  getUserGroupById,
  getUserGroupLangKnown,
  getUserGroupLangToLearn,
  getUserGroupTitle
} from '../../../../../../store/models/selectors';
import { TUserGroup, TUserLanguage } from '../../../../../../store/models/types';
import { LangUtil } from '../../../../../../../common/utils/lang-util';
import { getLibraryGroupId } from '../../../../../../store/general/selectors';


const mapStateToProps = (
  dispatch: any,
  {groupId}: IEditPlaylistItemHOCOwnProps
) => {
  return (
    state: IState,
    ownProps: IEditPlaylistItemHOCOwnProps
  ): IEditPlaylistItemFields | null => {
    const group: TUserGroup | undefined | null = getUserGroupById(state, groupId);
    if (!group) return null;

    const nativeLang = getUserGroupLangKnown(state, group);
    const targetLang = getUserGroupLangToLearn(state, group);

    const activeGroupId = getLibraryGroupId(state);

    return {
      id: groupId,
      label: getUserGroupTitle(state, group),
      videoCount: group.videos.length,
      phrasesCount: group.phrasesCount,
      nativeLangCode: LangUtil.checkLangCode(nativeLang?.code),
      targetLangCode: LangUtil.checkLangCode(targetLang?.code),
      publicGroup: group.public,
      videos: group.videos,
      audios: group.audios,
      level: group.level,
      isActive: activeGroupId === groupId,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {groupId}: IEditPlaylistItemHOCOwnProps
): IEditPlaylistItemEvents => ({
    onDelete: (history) => dispatch(EditPlaylistItemEvents.onDelete(groupId, history)),
    onEdit: () => dispatch(EditPlaylistItemEvents.onEdit(groupId)),
    onCopyLink: () => dispatch(EditPlaylistItemEvents.onCopyLink(groupId)),
    onAddVideoLib: () => dispatch(EditPlaylistItemEvents.onAddVideoLib()),
    onAddVideoYT: () => dispatch(EditPlaylistItemEvents.onAddVideoYT(groupId)),
    onAddText: () => dispatch(EditPlaylistItemEvents.onAddText(groupId)),
    onVideoClick: (videoId) => dispatch(EditPlaylistItemEvents.onVideoClick(groupId, videoId)),
    onVideoCopyLink: (videoId) => dispatch(EditPlaylistItemEvents.onVideoCopyLink(groupId, videoId)),
    onVideoDelete: (videoId) => dispatch(EditPlaylistItemEvents.onVideoDelete(groupId, videoId)),
    onAudioClick: (id) => dispatch(EditPlaylistItemEvents.onAudioClick(groupId, id)),
    onAudioDelete: (id) => dispatch(EditPlaylistItemEvents.onAudioDelete(groupId, id)),
    onAudioDownload: (id) => dispatch(EditPlaylistItemEvents.onAudioDownload(groupId, id)),
  }
);

export const EditPlaylistItemHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPlaylistItem);

EditPlaylistItemHOC.displayName = 'EditPlaylistItemHOC';