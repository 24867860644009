import { AudioPlayerStates, ICaptionsItem, IVocabularyPhrase } from '../../types/common';
import { IPlayerListener } from '../../components/dashboard/CaptionsContainer/TargetCaptions/components/types';
import { getDispatch } from '../../store';
import { setAudioPlayerState } from '../../store/general/actions';

export interface IAudioPlayerManagerListener extends IPlayerListener {
}

type TActiveResult = {
  captionIndex: number,
  wordIndex: number
}

export enum EAudioPlayerControllerMode {
  PLAY_COMMON,
  PLAY_CAPTION,
  PLAY_PHRASE,
  PLAY_PHRASE_LIST,
  PLAY_SELECT_TEXT,
  PLAY_TEACHER_PHRASE
}

export class AudioPlayerManager {

  private static instance: AudioPlayerManager;

  public static getInstance(): AudioPlayerManager {
    if (!AudioPlayerManager.instance)
      AudioPlayerManager.instance = new AudioPlayerManager();
    return AudioPlayerManager.instance;
  }

  private player: HTMLAudioElement;
  private captions: ICaptionsItem[];
  private activeCaptionIndex: number = -1;
  private activeWordIndex: number = 1
  private listeners: IAudioPlayerManagerListener[] = [];

  public setPlayer(player: HTMLAudioElement) {

    this.player = player;
    this.activeCaptionIndex = -1;
    this.activeWordIndex = -1;
    if (this.player) {
      const dispatch = getDispatch();
      this.player.addEventListener('pause', () => {
        dispatch(setAudioPlayerState(AudioPlayerStates.PAUSED));
      })
      this.player.addEventListener('play', () => {
        dispatch(setAudioPlayerState(AudioPlayerStates.PLAYING));
      })
    }

    this.startWatch();
  }

  public setCaptions(captions: ICaptionsItem[]) {
    this.activeCaptionIndex = -1;
    this.activeWordIndex = -1
    this.captions = captions;
  }

  public addListener(listener: IAudioPlayerManagerListener) {
    this.listeners.push(listener);
  }

  public removeListener(listener: IAudioPlayerManagerListener) {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  public async play(time?: number) {
    if (time !== undefined) {
      this.player.currentTime = time;
    }
    await this.player.play();
  }

  /*public async playPhrase(phrase: IVocabularyPhrase) {
    this.player.currentTime = phrase.startTime;
    await this.player.play();
  }*/

  public pause() {
    this.player.pause();
  }

  public getCurrentTime(): number {
    return this.player?.currentTime || 0;
  }

  private startWatch() {
    setInterval(this.checkActiveIndexes.bind(this), 50);
  }

  private checkActiveIndexes() {
    if (this.player && this.captions && this.captions.length > 0)  {
      const curentTime = this.getCurrentTime();
      const {captionIndex, wordIndex} = this.getActiveResult(curentTime);
      if (captionIndex !== this.activeCaptionIndex) {
        this.activeCaptionIndex = captionIndex;
        this.listeners.forEach(l => {
          if (l.onTargetIndexChange)
            l.onTargetIndexChange(this.activeCaptionIndex);
        });
      }
      if (wordIndex !== this.activeWordIndex) {
        this.activeWordIndex = wordIndex;
        this.listeners.forEach(l => {
          if (l.onTargetWordIndexChange)
            l.onTargetWordIndexChange(this.activeWordIndex);
        });
      }
    }
  }



  private getActiveResult(currentTime: number): TActiveResult {
    for(let i=0; i<this.captions.length; i++) {
      const startTime = this.captions[i].startTime;
      let endTime = this.captions[i].endTime;
      if (i < this.captions.length - 1) {
        if (endTime > this.captions[i+1].startTime) {
          endTime = this.captions[i+1].startTime;
        }
      }
      if (currentTime >= startTime && currentTime < endTime) {
        let wordIndex = -1;
        for (let wi=0; wi<this.captions[i].words.length; wi++) {
          const startTime = this.captions[i].words[wi].time;
          if (startTime < currentTime) {
            wordIndex = wi;
          }
        }
        return {captionIndex: i, wordIndex};
      }
    }
    return {captionIndex: -1, wordIndex: -1};
  }

  public isPlayerActive() {
    return this.player
      && this.player.currentTime > 0
      && !this.player.paused
      && !this.player.ended
      && this.player.readyState > 2;
  }
}

