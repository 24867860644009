import { IState } from '../../../../store/types';
import { getAudioById, isUserGroupsLoaded } from '../../../../store/models/selectors';
import { getMainLayoutPhrasesColumn } from '../../../../store/general/selectors';
import { connect } from 'react-redux';
import { Audio } from '../components/Audio';
import { IAudioEvents, IAudioFields } from '../components/types';
import { AudioEvents } from './events';
import { getCurrentAudioId } from '../../../../store/current-video/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IAudioFields => {
    const audioId = getCurrentAudioId(state);
    const audio = audioId ? getAudioById(state, audioId) : null;
    const groupsLoaded = isUserGroupsLoaded(state)
    const phrasesColumn = getMainLayoutPhrasesColumn(state);

    return {
      groupsLoaded,
      phrasesColumn,
      audioUrl: audio?.url
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IAudioEvents => ({
    onLoad: (audioId, groupId) => dispatch(AudioEvents.onLoad(audioId, groupId)),
    onUpdateAudioPlayer: player => dispatch(AudioEvents.onUpdateAudioPlayer(player)),
    onUpdateGroupId: groupId => dispatch(AudioEvents.onUpdateGroupId(groupId)),
  }
);

export const AudioHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(Audio);

AudioHOC.displayName = 'AudioHOC';
