import * as React from 'react';
import { List } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { IVideoLibraryRecentsProps } from './types';
import { AudioLibraryGroupItemHOC } from '../../AudioLibraryGroupItem/hocs/AudioLibraryGroupItemHOC';
import { TUserRecentViewItemType } from '../../../../../../common/rest/userRecentView/userRecentViewRest';
import { VideoLibraryGroupItemHOC } from '../../VideoLibraryGroupItem/hocs/VideoLibraryGroupItemHOC';

export const VideoLibraryRecents: React.FC<IVideoLibraryRecentsProps> = ({
  activeId,
  groupId,
  items
}) => {

  return (
    <>
      <List
        disablePadding
        sx={{
          p: theme.spacing(0, 1),
        }}
      >
        {items.map(i => {
          if (i.type === TUserRecentViewItemType.AUDIO) {
            return <AudioLibraryGroupItemHOC
              key={i.key}
              groupId={i.groupId}
              audioId={+i.key}
              isOpened={activeId === `audio-${i.key}` && groupId === i.groupId}
            />
          }
          if (i.type === TUserRecentViewItemType.VIDEO) {
          return <VideoLibraryGroupItemHOC
            key={i.key}
            isOpened={activeId === i.key && groupId === i.groupId}
            groupId={i.groupId}
            video={{
              id: i.key,
              videoKey: i.key,
              createdAt: null,
              info: i.info,
            }}
          />
          }
        })}
      </List>
    </>
  );
};
