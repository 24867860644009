import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { getCurrentMovieKey } from '../../../../../store/current-video/selectors';
import { IVideoLibraryRecentsEvents, IVideoLibraryRecentsFields } from '../../VideoLibraryRecents/components/types';
import { VideoLibraryRecents } from '../components/VideoLibraryRecents';
import { RecentsSelectors } from '../../../../../store/recents/selectors';
import { getLibraryGroupId } from '../../../../../store/general/selectors';

const mapStateToProps = (
  dispatch: any,
) => {
  return (
    state: IState,
  ): IVideoLibraryRecentsFields => {
    return {
      activeId: getCurrentMovieKey(state),
      groupId: getLibraryGroupId(state) || 0,
      items: RecentsSelectors.getList(state),
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
): IVideoLibraryRecentsEvents => ({
  }
);

export const VideoLibraryRecentsHOC = connect(
  mapStateToProps,
  null
)(VideoLibraryRecents);

VideoLibraryRecentsHOC.displayName = 'VideoLibraryRecentsHOC';