import { AppThunk } from '../../../../../store/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { ContentLibRest } from '../../../../../../common/rest/contentLib/contentLibRest';
import { IContentLibVideo } from '../../../../../store/contentLib/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';


export class ContentLibVideoListEvents {

  public static onLoadVideos(startIndex: number, count: number): AppThunk {
    return async (
      dispatch,
      getState
    ): Promise<IContentLibVideo[]> => {
      const groupId = ContentLibSelectors.getSelectedGroupId(getState());
      const {text: searchText, targetLangCode, nativeLangCode} = ContentLibSelectors.getSearchFilter(getState());

      try {
        const groups = await ContentLibRest.searchGroups({
          targetLangCode,
          nativeLangCode,
          searchText,
          maxVideoCount: 0,
        });
        dispatch(ContentLibActions.setGroupsSearchResult({ groups }));
      } catch(e) {
        console.error(e);
      }
      
      return ContentLibEffects.searchVideos({
        groupId, searchText, targetLangCode, nativeLangCode, startIndex, count
      });
    }
  }

  public static onVideoClick(video: IContentLibVideo | any): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const groupId = ContentLibSelectors.getSelectedGroupId(getState()) || video.groupId;
      if (video) {
        dispatch(ContentLibActions.setVideoPopup({groupId, videoId: video.id, video}));
      }
    }
  }

}