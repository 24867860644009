import { IPlayerProvider, VideoPlayerProvider } from './videoPlayerProvider';
import { isCurrentAudioMovie } from '../../../store/current-video/selectors';
import { getState } from '../../../store';
import { AudioPlayerProvider } from './audioPlayerProvider';

const audioProvider = new AudioPlayerProvider();
const videoProvider = new VideoPlayerProvider();

export class PlayerProvider {

  public static getProvider() {
    return isCurrentAudioMovie(getState()) ? audioProvider : videoProvider;
  }

}