import { IState } from '../../../../store/types';
import { PhraseListSelectors } from '../../../../store/phrase-list/selectors';
import {
  getAuthUser,
  getMainViewMode,
  isPhrasesExist,
} from '../../../../store/general/selectors';
import { VocabularyComponentUtils } from '../../Vocabulary/vocabulary/utils/VocabularyComponentUtils';
import { EPhraseListMode } from '../../../../store/phrase-list/types';
import { getActiveGroupId } from '../../../../store/models/selectors';
import { getCurrentMovieKey } from '../../../../store/current-video/selectors';
import { connect } from 'react-redux';
import { PhraseList } from '../components/PhraseList';
import { IPhraseListEvents, IPhraseListFields } from '../components/types';
import { PhraseListEvents } from './events';
import { EVocabularyPhraseType } from '../../../../types/common';
import { getVideoTeacherUserActiveId, getVideoTeacherUsers } from '../../../../store/videos/selectors';
import { EMPTY_ARRAY } from '../../../../../common/types';
import { PhraseDetailsPanelSelectors } from '../../PhraseDetails/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseListFields => {
    const teacherActiveId = getVideoTeacherUserActiveId(state);
    const phraseList = teacherActiveId
      ? EMPTY_ARRAY
      : PhraseListSelectors.findPhrasesByTypes(state, [
        EVocabularyPhraseType.DEFAULT,
        EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED,
        EVocabularyPhraseType.LESSON
      ])
        .filter(phrase => phrase.fullPhrase?.trim()?.length || phrase.type === EVocabularyPhraseType.LESSON)
        .map(phrase => phrase.id);

    const listMode = PhraseListSelectors.getListMode(state);
    const listModeLang = PhraseListSelectors.getListModeLang(state);
    const phrasesPlayStatus = PhraseListSelectors.getPlayMode(state);
    const phrasesExist = isPhrasesExist(state);
    const listModes = VocabularyComponentUtils.getListModes(state);
    const activeListMode = VocabularyComponentUtils.getListMode(state, listMode, listModeLang) ||
      VocabularyComponentUtils.getDefaultListMode(state);
    const lazyLoadingPhrases = listMode !== EPhraseListMode.CURRENT_VIDEO;

    const isTeacherList = !!getVideoTeacherUsers(state).filter(t => t.user).length;
    const text = PhraseDetailsPanelSelectors.getText(state);

    return {
      groupId: getActiveGroupId(state) || 0,
      videoId: getCurrentMovieKey(state),
      phraseList,
      phrasesExist,
      phrasesPlayStatus,
      listModes,
      lazyLoadingPhrases,
      activeListModeId: activeListMode.id,
      mainViewMode: getMainViewMode(state),
      isTeacherMode: getAuthUser(state)?.teacherMode,
      isTeacherList,
      text,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseListEvents => ({
    onPlay: () => dispatch(PhraseListEvents.onPlay()),
    onStop: () => dispatch(PhraseListEvents.onStop()),
    onChangeListMode: listMode => dispatch(PhraseListEvents.onChangeListMode(listMode)),
    onLoadPhrases: (count) => dispatch(PhraseListEvents.onLoadPhrases(count)),
    onAppendPhrases: (offset, count) => dispatch(PhraseListEvents.onAppendPhrases(offset, count)),
    onTargetCaptionIndexChange: index => dispatch(PhraseListEvents.onTargetCaptionIndexChange(index)),
    onKeyDown: event => dispatch(PhraseListEvents.onKeyDown(event)),
    onKeyUp: event => dispatch(PhraseListEvents.onKeyUp(event)),
    // onClickTitle: () => dispatch(PhraseListEvents.onClickTitle()),
    onAddAnnotation: (prevId, nextId) => dispatch(PhraseListEvents.onAddAnnotation(prevId, nextId)),
  }
);

export const PhraseListHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseList);

PhraseListHOC.displayName = 'PhraseListHOC';