import React from 'react';
import { IPhraseTranslationProps } from './types';
import { alpha, Box, Button, CircularProgress, Fade, IconButton, Menu, Stack, Tooltip } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { UserLangsTranslationColors } from '../../../PhraseDetailsTabsEditor/PhraseDetailsTabTranslation/helpers/userLangsTranslationColors';
import { TextWithSpeakerHOC } from '../../../../../../common/components/TextWithSpeaker/hocs/TextWithSpeakerHOC';
import { CLASS_ONBOARDING_NOEVENT, CLASS_ONBOARDING_TRIGGER } from '../../../Onboarding/constants';
import { PhraseTranslationSettingsHOC } from '../../../PhraseDetailsTabsEditor/PhraseDetailsTabTranslation/PhraseTranslationSettings/hocs/PhraseTranslationSettingsHOC';
import { SettingsIcon } from '../../../../../../../icons/SettingsIcon';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { Modal } from '../../../../../../common/components/Modal/Modal';
import { DisketteIcon } from '../../../../../../../icons/DisketteIcon';
import { OneTimeTooltipTranslationsHOC } from '../../../OneTimeTooltip/hocs/OneTimeTooltipTranslationsHOC/OneTimeTooltipTranslationsHOC';

export const PhraseTranslation: React.FC<IPhraseTranslationProps> = ({
	active,
	text,
	translateResults,
	processing,
	translateLangs,
	isTranslateContext,
	showTranslationsHint,
	onLoad,
	onRefresh,
	onCopyNote,
	onHideTranslationsHint,
}) => {
	if (!text) return null;

	const className = 'phrase-translation';
	const {isMobile} = React.useContext(DeviceContext);

	const [settingsEl, setSettingsEl] = React.useState<null | HTMLElement>(null);
	const [translateKey, setTranslateKey] = React.useState<string>(null);
	const [isShowButtons, setShowButtons] = React.useState(true);
	const showButtonsTimer = React.useRef(null);
	const [isShowSaveArea, setShowSaveArea] = React.useState(false);

	const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setSettingsEl(event.currentTarget);
		onHideTranslationsHint();
	};
	const handleSettingsClose = () => {
		setSettingsEl(null);
	};

	const onMouseIn = () => {
		if (isMobile) return;
		if (showButtonsTimer.current) clearTimeout(showButtonsTimer.current);
		showButtonsTimer.current = setTimeout(() => {
			setShowButtons(true);
		}, 300) as any;
	};
	const onMouseOut = () => {
		if (isMobile) return;
		if (showButtonsTimer.current) clearTimeout(showButtonsTimer.current);
		showButtonsTimer.current = setTimeout(() => {
			setShowButtons(false);
		}, 500) as any;
	};

	const onSaveMouseIn = () => {
		if (isMobile) return;
		setShowSaveArea(true);
	};
	const onSaveMouseOut = () => {
		if (isMobile) return;
		setShowSaveArea(false);
	};

	React.useEffect(() => {
		if (!settingsEl) {
			setTranslateKey(text + '|' + translateLangs.map(l => l.code).join('-'));
		}
	}, [active, text, translateLangs, settingsEl]);

	React.useEffect(() => {
		onLoad();
	}, [translateKey, isTranslateContext]);

	React.useEffect(() => {
		if (isMobile) return;

		if (translateResults && translateResults.length) {
			setShowButtons(true);
			setTimeout(() => {
				setShowButtons(false);
			}, 1000);
		} else {
			setShowButtons(false);
		}
	}, [translateResults]);

	let isContext = false;

	return (processing
		? <Stack
			sx={{
				width: 1,
				mt: theme.spacing(3),
				alignItems: 'center',
			}}
		>
			<CircularProgress size={theme.spacing(3)} />
		</Stack>
		: !translateResults ? null :
			<Stack
				className={className}
				sx={{
					position: 'relative',
					width: 1,
					pl: theme.spacing(3),

					'.mobile &': {
						p: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(3)}`,
					},

					'.mobile .phrase-details-column &': {
						pr: 0,
					},
				}}
				onMouseEnter={onMouseIn}
				onMouseLeave={onMouseOut}
			>
				<Stack
					className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step6
						${isShowSaveArea ? 'show-save-area' : ''}
					`}
					gap={theme.spacing(0.75)}

					sx={{
						position: 'relative',

						'&.show-save-area:before': {
							content: '""',
							position: 'absolute',
							left: theme.spacing(-.75),
							right: 0,
							top: theme.spacing(-.75),
							bottom: theme.spacing(-.75),
							background: alpha(theme.palette.warning[100], .2),
							borderRadius: theme.spacing(.75),
						},
					}}
				>
					{translateResults.map((result, index) => {
						let isContextBlock = !isContext && result.isContext;
						isContext = !!result.isContext;
						return <>
							{isContextBlock &&
								<Box
									sx={{
										mt: theme.spacing(1),
									}}
								/>
							}
							<Stack
								direction={'row'}
								key={result.langCode}
							>
								<TextWithSpeakerHOC
									key={result.langCode}
									textSx={{
										color: UserLangsTranslationColors.getColor(result.langCode as string),
									}}
									lang={String(result.langCode)}
									text={result.success ? result.result : 'Unknown error'}
								/>
								{!result.success &&
									<Button
										sx={{
											ml: theme.spacing(1),
										}}
										onClick={() => onRefresh(result.langCode as string)}
									>
										Refresh
									</Button>
								}
							</Stack>
						</>
					})}
				</Stack>

				<Fade
					in={isShowButtons || !!settingsEl || showTranslationsHint}
					timeout={{
						exit: 1000,
					}}
				>
					<Stack
						className={`${className}__buttons`}
						flexDirection={'row'}
						gap={theme.spacing(.5)}
						justifyContent={'space-between'}
						sx={{
							mt: theme.spacing(1),
						}}
					>
						<Tooltip
							title={'Translation settings'}
						>
							<IconButton
								className={`${CLASS_ONBOARDING_NOEVENT}`}
								sx={{
									width: theme.spacing(4),
									height: theme.spacing(4),
									ml: theme.spacing(-1),
									color: theme.palette.grey[400],

									'.MuiSvgIcon-root': {
										width: theme.spacing(2),
										height: theme.spacing(2),
										
									},
								}}
								onClick={handleSettingsClick}
							>
								<SettingsIcon />
							</IconButton>
						</Tooltip>
						{isMobile
							? <Modal
								onClose={handleSettingsClose}
								open={!!settingsEl}
								isMobile={isMobile}
								>
								<PhraseTranslationSettingsHOC />
							</Modal>
							: <Menu
								anchorEl={settingsEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								open={!!settingsEl}
								onClose={handleSettingsClose}
								sx={{
									'& .MuiPaper-root': {
										width: theme.spacing(35),
										mt: theme.spacing(1),
										boxShadow: theme.shadows[16],
									},
								}}
							>
								<PhraseTranslationSettingsHOC />
							</Menu>
						}

						<Button
							variant={'contained'}
							sx={{
								height: theme.spacing(4),
								p: `0 ${theme.spacing(1.25)}`,
								color: theme.palette.warning[900],
								background: theme.palette.warning[100],

								'&:hover, &:focus': {
									background: theme.palette.info[200],
								},

								'.MuiButton-startIcon': {
									mr: theme.spacing(.5),
								},
							}}
							startIcon={<DisketteIcon />}
							onClick={onCopyNote}
							onMouseMove={onSaveMouseIn}
							onMouseLeave={onSaveMouseOut}
						>
							Save
						</Button>

						<OneTimeTooltipTranslationsHOC />

					</Stack>
				</Fade>

			</Stack>
	);

}
