import { AppThunk, IState } from '../../../../../../store/types';
import { History } from 'history';
import {
  getActiveGroupId, getAudioById,
  getUserGroupById,
  getUserGroupLangToLearn,
  getUserGroups,
  getUserGroupWithLangCode
} from '../../../../../../store/models/selectors';
import { ELanguageStatus, EMainViewMode } from '../../../../../../store/general/types';
import { TUserGroup } from '../../../../../../store/models/types';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { removeUserGroups } from '../../../../../../../common/rest/userGroup/removeUserGroups';
import {
  removeGroupAction,
} from '../../../../../../store/models/actions';
import { getLibraryGroupId } from '../../../../../../store/general/selectors';
import { EExtMessageType, ExtMessageSender } from '../../../../../../../common/utils/ext-message-sender';
import { UserGroupEffects } from '../../../../../../effects/userGroupEffects';
import { LANG_CODE_AUTO_GEN_SUBSTR } from '../../../../../../types/common';
import { PhraseListActions } from '../../../../../../store/phrase-list/actions';
import { setEditPlaylistModalIdAction, setLibraryGroupIdAction, setMainViewMode, setShowAddNewAudioPopupAction, setShowAddNewVideoPopupAction, setSnackbarPanelAction } from '../../../../../../store/general/actions';
import { PlayerController } from '../../../../../../effects/player/manager/playerController';
import { Navigator } from '../../../../../../effects/navigator';
import { getUserStateStorage } from '../../../../../../../common/utils/local-storage/user-state';
import { EContentLibViewMode } from '../../../../../../store/contentLib/types';
import { ContentLibActions } from '../../../../../../store/contentLib/actions';
import { CopyGroupEffects } from '../../../../../../effects/copyGroupEffects';
import { removeVideoFromGroup } from '../../../../../../effects/phrase/phraseVocabularyEffect';
import { CopyVideoEffects } from '../../../../../../effects/copyVideoEffects';
import { getCurrentAudioId } from '../../../../../../store/current-video/selectors';
import { AudioEffects } from '../../../../../../effects/audioEffects';

export class EditPlaylistItemEvents {

  public static onDelete(groupId: number, history: History): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      const group = getUserGroupById(state, groupId);

      if (group) {
        const delGroupIds = [group.id];
        await EditPlaylistItemEvents.removeGroup(dispatch, group.id);
        const targetLang = getUserGroupLangToLearn(state, group);
        if (targetLang) {
          if (targetLang.code.indexOf(LANG_CODE_AUTO_GEN_SUBSTR) < 0) {
            const autoGenTargetLangGroup = getUserGroupWithLangCode(state, ELanguageStatus.TO_LEARN, targetLang.code + LANG_CODE_AUTO_GEN_SUBSTR);
            if (autoGenTargetLangGroup) {
              await EditPlaylistItemEvents.removeGroup(dispatch, autoGenTargetLangGroup.id);
              delGroupIds.push(autoGenTargetLangGroup.id);
            }
          }
        }

        const groups: TUserGroup[] = getUserGroups(getState());
        if (groups && groups.length > 0) {
          UserGroupEffects.setLibraryGroupId(groups[0].id);
        } else {
          UserGroupEffects.setLibraryGroupId(0);
        }

        const activeGroupId = getActiveGroupId(state);
        const libGroupId = getLibraryGroupId(state);
        const activeGroupDeleted: boolean = Boolean(activeGroupId && delGroupIds.includes(activeGroupId));
        const libGroupDeleted: boolean = Boolean(libGroupId && delGroupIds.includes(libGroupId));
        if (libGroupDeleted && !activeGroupDeleted) {
          UserGroupEffects.setLibraryGroupId(activeGroupId || 0);
        }
        ExtMessageSender.send({
          type: EExtMessageType.SET_USER_GROUPS,
          payload: {userGroups: getUserGroups(getState())}
        });
      }

    }
  }

  private static async removeGroup(
    dispatch: ThunkDispatch<IState, unknown, Action<string>>,
    groupId: number)
  {
    await dispatch(removeUserGroups(groupId));
    dispatch(removeGroupAction(groupId));
    dispatch(PhraseListActions.removeByGroup(groupId));
  }

  public static onEdit(
    groupId: number,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setEditPlaylistModalIdAction(groupId));
    }
  }

  public static onCopyLink(
    groupId: number,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const {url} = await CopyGroupEffects.createCopyLink(groupId);
      await navigator.clipboard.writeText(url);
      dispatch(setSnackbarPanelAction(true, 'Link to public playlist copied to clipboard'));
    }
  }

  public static onAddVideoLib(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setMainViewMode(EMainViewMode.CONTENT_LIBRARY));
      dispatch(ContentLibActions.setViewMode(EContentLibViewMode.VIDEO_LIST));
    }
  }

  public static onAddVideoYT(
    groupId: number,
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setLibraryGroupIdAction(groupId));
      dispatch(setShowAddNewVideoPopupAction(true));
    }
  }

  public static onAddText(
    groupId: number,
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setLibraryGroupIdAction(groupId));
      dispatch(setShowAddNewAudioPopupAction(true))
    }
  }

  public static onVideoDelete (
    groupId: number,
    videoId: string,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      await dispatch(removeVideoFromGroup(videoId, groupId));
      dispatch(PhraseListActions.removeByVideoGroup(videoId, groupId));

    }
  }

  public static onVideoClick (
    groupId: number,
    videoId: string,
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      // const userStorage = getUserStateStorage();
      // userStorage.setVideoInfo(videoId, groupId, 0);
      // userStorage.setLibraryGroupId(groupId);

      PlayerController.getInstance().resetMode();
      Navigator.openVideo(groupId, videoId);
    }
  }

  public static onVideoCopyLink (
    groupId: number,
    videoId: string,    
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (!getUserGroupById(getState(), groupId)) {
        return dispatch(setSnackbarPanelAction(true, 'Unable create copy link: invalid playlist'));
      }
      const url = await CopyVideoEffects.createCopyLink(videoId, groupId);
      await navigator.clipboard.writeText(url);
      dispatch(setSnackbarPanelAction(true, 'Link to public video copied to clipboard'));
    }
  }

  public static onAudioDelete (
    groupId: number,
    audioId: number,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const currentAudioId = getCurrentAudioId(getState());
      await AudioEffects.remove(audioId, groupId);
      if (currentAudioId === audioId) {
        Navigator.openGroup(groupId);
      }

    }
  }

  public static onAudioDownload (
    groupId: number,
    audioId: number,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const audio = getAudioById(getState(), audioId);
      if (audio?.url) {
        window.open(audio.url);
      }
    }
  }



  public static onAudioClick (
    groupId: number,
    audioId: number,
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const userStorage = getUserStateStorage();
      userStorage.setAudioInfo(audioId, groupId);
      userStorage.setLibraryGroupId(groupId);

      PlayerController.getInstance().resetMode();
      Navigator.openAudio(groupId, audioId);
    }
  }


}
