import React from 'react';
import { IPhraseDetailsTabsEditorRouterProps, PHRASE_DETAILS_TAB_EDITOR_CLASS_NAME } from './types';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { PhraseDetailsTabsEditorContainerHOC } from '../../PhraseDetailsTabsEditorContainer/hocs/PhraseDetailsTabsEditorContainerHOC';
import { PhraseDetailsTabsLibContainerHOC } from '../../PhraseDetailsTabsLibContainer/hocs/PhraseDetailsTabsLibContainerHOC';
import { PhraseDetailsTabsCreateContainerHOC } from '../../PhraseDetailsTabsCreateContainer/hocs/PhraseDetailsTabsCreateContainerHOC';
import { Stack } from "@mui/material";
import { theme } from "../../../../../../common/styles/theme";
import { PhraseDetailsTabTranslationContainerHOC } from '../../PhraseDetailsTabTranslation/PhraseDetailsTabTranslationContainer/hocs/PhraseDetailsTabTranslationContainerHOC';
import { MIN_WIDTH } from '../../../../../../common/contexts/DeviceContext';
import { Modal } from '../../../../../../common/components/Modal/Modal';

export const PhraseDetailsTabsEditorRouter: React.FC<IPhraseDetailsTabsEditorRouterProps> = ({
  show,
  viewMode,
  onChange,
  onLoad,
}) => {

  if (!show) return null;

  React.useEffect(() => {
    onChange();
  }, [viewMode]);

  React.useEffect(() => {
    onLoad();
  }, []);


  return (
    <Modal
			open={show}
      isCloseButton={false}
			onClose={()=>{}}
			PaperProps={{
				sx: {
					position: 'fixed',
					top: 0,
					left: 0,
					width: `100vw`,
					height: `100vh`,
					maxHeight: `100vh !important`,
					background: theme.palette.secondary.main,
					borderRadius: 0,

					'& > .MuiStack-root': {
						gap: `0 !important`,
						p: `0 !important`,
						maxHeight: `100% !important`,
            height: 1,
					},
				},
			}}
		>
    <Stack
      direction={'row'}
      sx={{
        gap: theme.spacing(2),
        width: 1,
        minWidth: MIN_WIDTH + 'px',
        minHeight: 1,
        overflowY: 'auto',
        background: theme.palette.grey[100],
        p: theme.spacing(2),
      }}
      className={PHRASE_DETAILS_TAB_EDITOR_CLASS_NAME}
    >
      {viewMode === EPhraseDetailsTabsEditorViewMode.MAIN &&
        <PhraseDetailsTabsEditorContainerHOC />
      }
      {viewMode === EPhraseDetailsTabsEditorViewMode.CREATE &&
        <PhraseDetailsTabsCreateContainerHOC />
      }
      {viewMode === EPhraseDetailsTabsEditorViewMode.LIBS &&
        <PhraseDetailsTabsLibContainerHOC />
      }
      {viewMode === EPhraseDetailsTabsEditorViewMode.TRANSLATIONS &&
        <PhraseDetailsTabTranslationContainerHOC />
      }
    </Stack>
    </Modal>
  );
}
