export class TimeUtils {

  public static secondsToString(seconds: number): string {
    const res: any[] = [];

    const hours = Math.floor(seconds / 3600);
    const hoursString = hours > 9 ? '' + hours : '0' + hours;
    if (hours) res.push(hoursString);

    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const minString = minutes > 9 ? '' + minutes : '0' + minutes;
    res.push(minString);

    const sec = Math.floor(seconds - hours * 3600 - minutes * 60);
    const secString = sec > 9 ? '' + sec : '0' + sec;
    res.push(secString);

    return res.join(':');
  }

  public static stringToSeconds(string: string): number {
    const arr = string.split(':');
    if (arr.length === 0) return 0;

    const result = arr.reduceRight((sum: number, parsedTime, index) => {
      const mod = arr.length - index - 1;
      const modifier = Math.pow(60, mod);
      const int = parseInt(parsedTime);
      return sum + int * modifier;
    }, 0);
    return isNaN(result) ? 0 : result;
  }


  public static pause(time: number): Promise<void> {
    return new Promise(resolve => {
      setTimeout(resolve, time)
    })
  }
}
