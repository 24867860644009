import { AppThunk } from '../../../../../store/types';
import { EPhrasesColumnType } from '../../../../../store/general/types';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';


export class PhrasesColumnEvents {

	public static setType(type: EPhrasesColumnType): AppThunk {
		return (
			dispatch,
			getState
		) => {
			MainLayoutEffects.setPhrasesColumn(type);
		}
	}

}