import { AppThunk } from '../../../../../store/types';
import { getOneTimeTooltipsStorage } from '../../../../../../common/utils/local-storage/onetime-tooltips';
import { setOneTimeTooltipAction, setVideoTutorialModeAction } from '../../../../../store/general/actions';
import { getOneTimeTooltip } from '../../../../../store/general/selectors';
import { batch } from 'react-redux';
import { AuthUserEffects } from '../../../../../effects/authUserEffects';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';
import { EVideoTutorialType } from '../../../../../store/models/types';

export class OneTimeTooltipSaveForMagicEvents {

	public static onClose(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			const isDone = getOneTimeTooltip(getState()).showSavedForMagicTooltip;
			batch(() => {
				if (isDone) {
					getOneTimeTooltipsStorage().setShow({ showSavedForMagic: false });
					dispatch(setOneTimeTooltipAction({ showSavedForMagicTooltip: false }));
				}
				//  else {
				// 	getOneTimeTooltipsStorage().setShow({ showSaveForMagic: false });
				// 	dispatch(setOneTimeTooltipAction({ showSaveForMagicTooltip: false }));

				// 	getOneTimeTooltipsStorage().setShow({ showSavedForMagic: true });
				// 	dispatch(setOneTimeTooltipAction({ showSavedForMagicTooltip: true }));
				// }
			});
		}
	}

	public static onShowDone(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			batch(() => {
				getOneTimeTooltipsStorage().setShow({ showSaveForMagic: false });
				dispatch(setOneTimeTooltipAction({ showSaveForMagicTooltip: false }));

				getOneTimeTooltipsStorage().setShow({ showSavedForMagic: true });
				dispatch(setOneTimeTooltipAction({ showSavedForMagicTooltip: true }));

				AuthUserEffects.sendConfirmEmail();
			});
		}
	}

	public static onClickLink(
		e: MouseEvent
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			const isDone = getOneTimeTooltip(getState()).showSavedForMagicTooltip;
			if (isDone) {
				EventsRouter.trackEvent(Events.SAVE_FOR_MAGIC_INBOX_CLICKED);
			} else {
				e.preventDefault();
				dispatch(setVideoTutorialModeAction(EVideoTutorialType.COMMON));
				EventsRouter.trackEvent(Events.SAVE_FOR_MAGIC_HOW_CLICKED);
			}
		}
	}

}