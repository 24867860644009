import { EVocabularyPhraseType, IVocabularyPhrase } from '../../../../types/common';
import { getDispatch, getState } from '../../../../store';
import { PhraseListSelectors } from '../../../../store/phrase-list/selectors';
import { PhraseListActions } from '../../../../store/phrase-list/actions';
import { EPhraseListPlayMode } from '../../../../store/phrase-list/types';
import { PlayerManager } from '../playerManager';
import { Pause } from '../../../../types/pause-constants';
import { EPlayerControllerMode, PlayerController } from '../playerController';
import { getActiveGroupId } from '../../../../store/models/selectors';
import { getCurrentAudioId, getCurrentMovieKey, isCurrentAudioMovie } from '../../../../store/current-video/selectors';
import { UserGroupEffects } from '../../../userGroupEffects';
import { PlayerFactory } from '../../player-factory';
import { loadVideoEffect } from '../../../video_effects';
import { batch } from 'react-redux';
import { getTargetCaptionByIndex, getTargetCaptionsByIndexRange } from '../../../../store/videos/selectors';
import { PhraseEffects } from '../../../phrase/PhraseEffects';
import { AudioEffects } from '../../../audioEffects';

export abstract class PlayPhraseBaseHandler {


  protected getStartPlayPhrase(fromFirstPhrase: boolean, phrases: IVocabularyPhrase[]) {
    let phraseId = phrases[0].id;
    if (!fromFirstPhrase) {
      phraseId = PhraseListSelectors.getCurrentPhraseId(getState());
      if (!phraseId) {
        phraseId = phraseId = phrases[0].id;
      }
    }
    let phrase = phrases.find(p => p.id === phraseId);
    if (!phrase) {
      phrase = phrases[0];
    }
    return phrase;
  }

  public async stop() {
    const dispatch = getDispatch();
    dispatch(PhraseListActions.setPlayMode(EPhraseListPlayMode.STOP));
    await PlayerManager.getInstance().pausePlay();
  }

  public reset() {
    const dispatch = getDispatch();
    dispatch(PhraseListActions.setPlayMode(EPhraseListPlayMode.STOP));
    dispatch(PhraseListActions.setCurrentPhraseId(0));
    dispatch(PhraseListActions.setPlayPhraseId(0));
    dispatch(PhraseListActions.setTopPhraseId(0));
  }

  public async repeat() {
    const phrase = this.findRepeatPhrase();
    if (phrase) {
      this.startPhrase(phrase);
    }
  }

  public playNext() {
    const phrase = this.findNextPhrase();
    if (phrase) {
      this.startPhrase(phrase);
    }
  }

  public playPrev() {
    const phrase = this.findPrevPhrase();
    if (phrase) {
      this.startPhrase(phrase);
    }
  }

  public async resumePlay() {
    const phrase = this.getCurrentPhrase();
    if (phrase) {
      const playManager = PlayerManager.getInstance();
      await playManager.startPlay();
      playManager.setTimer(phrase.endTime);
    }
  }

  public async onTimerDone() {
    await PlayerManager.getInstance().pausePlay();
    const pause = PhraseListSelectors.getPauseDelay(getState());
    if (pause == Pause.FullStopValue) {
      this.finish();
    } else {
      const phrase = this.getNextPhrase();
      if (!phrase) {
        return this.finish();
      }
      if (pause == Pause.NoPauseValue) {
        this.startPhrase(phrase);
      } else {
        PlayerController.getInstance().startWaitTimer(pause * 1000);
      }
    }
  }

  public onWaitPauseFinish() {
    const phrase = this.getNextPhrase();
    if (!phrase) {
      return this.finish();
    }
    this.startPhrase(phrase);
  }

  public async finish() {
    const dispatch = getDispatch();
    PlayerController.getInstance().setMode(EPlayerControllerMode.PLAY_COMMON);
    dispatch(PhraseListActions.setCurrentPhraseId(0));
    dispatch(PhraseListActions.setPlayPhraseId(0));
    dispatch(PhraseListActions.setPlayMode(EPhraseListPlayMode.STOP));
    this.onAfterFinish();
  }

  protected onAfterFinish() {}

  protected getNextPhrase(): IVocabularyPhrase | null {
    const phrases = this.getPhraseList();
    const currentPhraseId = PhraseListSelectors.getCurrentPhraseId(getState());
    if (!currentPhraseId) return null;
    const index = phrases.findIndex(p => p.id === currentPhraseId);
    if (index >= 0 && index < phrases.length - 1) {
      return phrases[index + 1];
    }
    return null;
  }

  private getCurrentPhrase(): IVocabularyPhrase | null {
    const phrases = this.getPhraseList();
    const currentPhraseId = PhraseListSelectors.getCurrentPhraseId(getState());
    if (!currentPhraseId) return null;
    const index = phrases.findIndex(p => p.id === currentPhraseId);
    if (index >= 0 && index < phrases.length) {
      return phrases[index];
    }
    return null;
  }

  protected async startPhrase(phrase: IVocabularyPhrase) {
    const dispatch = getDispatch();
    const state = getState();
    const groupId = getActiveGroupId(state);
    const videoId = getCurrentMovieKey(state);

    if (phrase.userGroupId && phrase.videoKey && (groupId !== phrase.userGroupId || videoId !== phrase.videoKey)) {
      UserGroupEffects.setLibraryGroupId(phrase.userGroupId);
      if (isCurrentAudioMovie(state)) {
        await AudioEffects.load(getCurrentAudioId(state), groupId)
      } else {
        const playerApi = await PlayerFactory.getPlayerApi();
        await dispatch(loadVideoEffect(phrase.videoKey, playerApi, phrase.userGroupId, true, phrase.startTime));
      }
    }
    batch(() => {
      dispatch(PhraseListActions.setCurrentPhraseId(phrase.id));
      dispatch(PhraseListActions.setPlayPhraseId(phrase.id));
      dispatch(PhraseListActions.setTopPhraseId(phrase.id));
    })
    this.onBeforeStartPhrase(phrase);
    //const offset = PhraseListSelectors.getPlayOffset(state);
    const playManager = PlayerManager.getInstance();
    const playRange = this.getPhrasePlayTimeRange(phrase);
    await playManager.startPlay(playRange.startTime);
    playManager.setTimer(playRange.endTime);
  }

  private getPhrasePlayTimeRange(phrase: IVocabularyPhrase): {
    startTime: number,
    endTime: number
  } {
    const state = getState();
    const contextExist = PhraseEffects.isContextExist(phrase);
    const offset = PhraseListSelectors.getPlayOffset(state);
    if (contextExist) {
      return {
        startTime: phrase.startTime - offset.start,
        endTime: phrase.endTime + offset.end
      }
    }
    const captions = getTargetCaptionsByIndexRange(state, phrase.startCaptionIndex, phrase.endCaptionIndex);
    if (captions?.length < 1) {
      return {
        startTime: phrase.startTime - offset.start,
        endTime: phrase.endTime + offset.end
      }
    }
    return {
      startTime: captions[0].startTime,
      endTime: captions[captions.length - 1].endTime
    }
  }

  protected onBeforeStartPhrase(phrase: IVocabularyPhrase) {

  }

  protected getPhraseList(): IVocabularyPhrase[] {
    return PhraseListSelectors.findPhrasesByType(getState(), EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED) || [];
  }

  protected findRepeatPhrase(): IVocabularyPhrase | null {
    const phrases = this.getPhraseList();
    const currentPhraseId = PhraseListSelectors.getPlayPhraseId(getState());
    if (!currentPhraseId) return null;
    let index = phrases.findIndex(p => p.id === currentPhraseId);
    if (index >= 0 && index < phrases.length) {
      return phrases[index];
    }
    return null;
  }

  protected findNextPhrase(): IVocabularyPhrase | null {
    const phrases = this.getPhraseList();
    const currentPhraseId = PhraseListSelectors.getPlayPhraseId(getState());
    if (!currentPhraseId) return null;
    let index = phrases.findIndex(p => p.id === currentPhraseId) + 1
    if (index >= 0 && index < phrases.length) {
      return phrases[index];
    }
    return null;
  }

  protected findPrevPhrase(): IVocabularyPhrase | null {
    const phrases = this.getPhraseList();
    const currentPhraseId = PhraseListSelectors.getPlayPhraseId(getState());
    if (!currentPhraseId) return null;
    const index = phrases.findIndex(p => p.id === currentPhraseId) - 1;
    if (index >= 0 && index < phrases.length) {
      return phrases[index];
    }
    return null;
  }
}