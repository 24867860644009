import { AppThunk } from '../store/types';
import { getUserGroupById } from '../store/models/selectors';
import { setGroupPhraseCount, setGroupUserPhraseCount } from '../store/models/actions';
import { batch } from 'react-redux';

export const increasePhraseCount = (
  groupId: number,
  count: number = 1
): AppThunk => (
  dispatch,
  getState
) => {
  const state = getState();
  const group = getUserGroupById(state, groupId);
  if (!group) return; //todo throw

  batch(() => {
    dispatch(setGroupPhraseCount(groupId, +group.phrasesCount + count));
    dispatch(setGroupUserPhraseCount(groupId, +group.userPhrasesCount + count));
  })
};

export const decreasePhraseCount = (
  groupId: number,
  count: number = 1,
  userCount = 1
): AppThunk => (
  dispatch,
  getState
) => {
  const state = getState();
  const group = getUserGroupById(state, groupId);
  if (!group) return; //todo throw

  batch(() => {
    dispatch(setGroupPhraseCount(groupId, +group.phrasesCount - count));
    dispatch(setGroupUserPhraseCount(groupId, +group.userPhrasesCount - userCount));
  });

};
