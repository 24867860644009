import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { OneTimeTooltipTutorialEvents } from './events';
import { EOneTimeTooltipType, OneTimeTooltipTextProvider } from '../oneTimeTooltipTextProvider';
import { getMainViewMode, getOneTimeTooltip, getVideoTutorialMode } from '../../../../../store/general/selectors';
import { OneTimeTooltipArrow } from '../../components/OneTimeTooltipArrow/OneTimeTooltipArrow';
import { IOneTimeTooltipArrowEvents, IOneTimeTooltipArrowFields } from '../../components/OneTimeTooltipArrow/types';
import { EMainViewMode } from '../../../../../store/general/types';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IOneTimeTooltipArrowFields => {
		const oneTimeTooltips = getOneTimeTooltip(state);

		return {
			isCloseOutside: true,
			show: oneTimeTooltips.showTutorialTooltip &&
				!oneTimeTooltips.showTutorialPopup &&
				getMainViewMode(state) !== EMainViewMode.PHRASE_DETAILS_TABS_EDITOR,
			text: OneTimeTooltipTextProvider.getText(EOneTimeTooltipType.TUTORIAL),
			targetSelector: '.button-help',
			tooltipProps: {
				placement: 'left-start',
			},
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IOneTimeTooltipArrowEvents => ({
	onClose: () => dispatch(OneTimeTooltipTutorialEvents.onClose()),
});

export const OneTimeTooltipTutorialHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(OneTimeTooltipArrow);

OneTimeTooltipTutorialHOC.displayName = 'OneTimeTooltipTutorialHOC';