import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { CardMedia, ListItem, ListItemButton, ListItemSecondaryAction, Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { IVideoLibraryGroupItemProps } from './types';
import { LangFlag } from '../../../LangFlag/LangFlag';
import { ConfirmPopup } from '../../../ConfirmPopup/ConfirmPopup';
import { TrashIcon } from '../../../../../../../icons/TrashIcon';
import { IconMenuItem } from '../../../../../../common/components/IconMenuItem/IconMenuItem';
import { LinkIcon } from '../../../../../../../icons/LinkIcon';
import { ButtonMenu } from '../../../../../../common/components/ButtonMenu/ButtonMenu';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';

export const VideoLibraryGroupItem: React.FC<IVideoLibraryGroupItemProps> = ({
  isOpened,
  title,
  thumb,
  author,
  langCodes,
  onClick,
}) => {

  const { isTouch } = React.useContext(DeviceContext);

  const imageStyle = {
    minWidth: theme.spacing(14.25),
    maxWidth: theme.spacing(14.25),
    height: theme.spacing(9),
    borderRadius: theme.spacing(0.75),
  };

  const handleClick = () => {
		let modal;
		setTimeout(() => {
			modal = document.querySelector('.recent-drawer > .MuiModal-backdrop') as HTMLElement;
			if (modal) modal.click();
		}, 100);
		setTimeout(() => {
			modal = document.querySelector('.menu-drawer > .MuiModal-backdrop') as HTMLElement;
			if (modal) modal.click();
		}, 200);
		onClick();
	}

  return (
    <ListItem
      className={'video-library-item'}
      sx={{ p: 0 }}
    >
      <ListItemButton
        onClick={handleClick}
        sx={{
          p: theme.spacing(),
          borderRadius: theme.spacing(0.75),
          bgcolor: isOpened ? `${theme.palette.primary[100]} !important` : ''
        }}
      >
        <Stack
          direction='row'
          spacing={theme.spacing(2)}
          sx={{ alignItems: 'center', pr: theme.spacing(4), overflow: 'hidden' }}
        >
          <CardMedia
            component='img'
            height={theme.spacing(9.25)}
            image={thumb}
            alt={title}
            sx={imageStyle}
          />
          <Stack
              spacing={'3px'}
              sx={{
                maxWidth: `calc(100% - ${theme.spacing(15.75)})`, // - img width + gap
                height: theme.spacing(9) // image height
              }}
          >
            <Text
              fontWeight={600}
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden'
              }}
            >
              {title}
            </Text>
            <Text
              fontWeight={600}
              noWrap
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: theme.palette.grey[400]
              }}
            >
              {author}
            </Text>
            {!!langCodes && !!langCodes.length &&
              <Stack
                direction='row'
                sx={{ height: theme.spacing(2) }}
                spacing={theme.spacing()}
              >
                {langCodes.map((langCode) => (
                  <LangFlag code={langCode} showCode={true} key={langCode}/>
                ))}
              </Stack>
            }
          </Stack>
        </Stack>
      </ListItemButton>

    </ListItem>
  );
};
