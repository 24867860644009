import React from 'react';
import { Stack } from '@mui/material';
import { IPlayButtonProps } from './types';
import { PlayIcon } from '../../../../../../icons/PlayIcon';
import { PauseIcon } from '../../../../../../icons/PauseIcon';
import { theme } from '../../../../../common/styles/theme';

export function PlayButton ({
	className,
	isPlay,
	onClick,
}: IPlayButtonProps) {

	return (
		<Stack
			className={`${className} ${isPlay ? 'pause' : 'play'}`}
			sx={{
				justifyContent: 'center',
				alignItems: 'center',
				width: theme.spacing(3.5),
				height: theme.spacing(3.5),
				color: theme.palette.common.white,
				background: theme.palette.primary.main,
				borderRadius: '50%',
				cursor: 'pointer',
				transition: 'all .3s ease',

				'svg': {
					width: theme.spacing(1.3),
					height: theme.spacing(1.3),
				},

				'&.play svg': {
					ml: theme.spacing(.25),
				},

				'&:before': {
					content: '""',
					position: 'absolute',
					inset: '-5px',
				},
			}}
			onClick={onClick}
		>
			{isPlay
				? <PauseIcon />
				: <PlayIcon />
			}
		</Stack>
	);
};