import { AppThunk } from '../../../../../store/types';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { Navigator } from '../../../../../effects/navigator';
import { getOnboarding } from '../../../../../store/general/selectors';
import { setOnboardingAction } from '../../../../../store/general/actions';

export class AudioLibraryGroupItemEvents {

  public static onClick (
    audioId: number,
    groupId: number
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().resetMode();
      Navigator.openAudio(groupId, audioId);

      const onboarding = getOnboarding(getState());
      if (onboarding && onboarding.isActive && !onboarding.step) {
        dispatch(setOnboardingAction({ step: 1 }))
      }
    }
  }


}
