import { AppThunk } from '../../../../../store/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';

export class ContentLibraryHeaderEvents {

  public static onChangeViewMode(viewMode: EContentLibViewMode): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(ContentLibActions.setViewMode(viewMode));
      if (viewMode != EContentLibViewMode.GROUP_LIST) {
        // dispatch(ContentLibActions.setGroupsSearchResult({ groups: [], loading: false }));
      }
    }
  }


}