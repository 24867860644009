import React from 'react';
import { Box, Stack } from '@mui/material';
import { IOneTimeTooltipFullscreenProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';

export function OneTimeTooltipFullscreen ({
	show,
}: IOneTimeTooltipFullscreenProps) {
	if (!show) return null;

	const { isFullScreen, isMobile } = React.useContext(DeviceContext);

	return !isFullScreen && !isMobile && (
		<Stack
			sx={{
				position: 'absolute',
				zIndex: 100,
				left: `calc(100% + ${theme.spacing(6)})`,
				top: `calc(100% - ${theme.spacing(3.75)})`,
				width: 1,
				pointerEvents: 'none',
				color: theme.palette.grey[300],
				fontSize: theme.typography.subtitle2,
				fontWeight: 400,
			}}
		>
			<ArrowLightLeftIcon
				sx={{
					position: 'absolute',
					ml: theme.spacing(-3),
				}}
			/>
			<Box>
				Try full screen <br/>
				with subtitles
			</Box>
		</Stack>	
	);
}
