import { AppThunk } from '../../../../store/types';
import { AuthManager } from '../../../../../common/utils/auth-manager';
import { prepareAfterOpen } from '../../../../effects/prepareAfterOpen';
import { getAuthUser, getCurrentFocusArea } from '../../../../store/general/selectors';
import { ECurrentFocusArea } from '../../../../store/general/types';
import { setCurrentFocusArea } from '../../../../store/general/actions';
import { UrlUtil } from '../../../../../common/utils/url-util';
import { changeCurrentAudioId, changeCurrentVideoId } from '../../../../store/current-video/actions';
import { setActiveGroupId } from '../../../../store/models/actions';
import { getActiveGroupId } from '../../../../store/models/selectors';
import { getCurrentAudioId, getCurrentVideoKey } from '../../../../store/current-video/selectors';

export class MainContainerEvents {

  public static onLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ): Promise<any> => {
      if (!getAuthUser(getState())) {
        const result = await AuthManager.autoLogin();
        if (result && result.authUser) {
          dispatch(prepareAfterOpen(result.authUser, result.settings, result.appSettings));
        }
      }
    }
  }

  public static onMouseDown(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (getCurrentFocusArea(getState()) !== ECurrentFocusArea.MAIN) {
        dispatch(setCurrentFocusArea(ECurrentFocusArea.MAIN));
      }
    }
  }

  public static onChangeRoute(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const route = UrlUtil.getRoute();
      const type = route && route.length && route.length > 1 && route[1];
      const groupId = route && route.length && route.length > 2 && +route[2];
      const id = route && route.length && route.length > 3 && decodeURIComponent(route[3]);
      if (groupId) {
        const activeGroupId = getActiveGroupId(getState());
        if (activeGroupId !== groupId) dispatch(setActiveGroupId(groupId));
      }
      const currentAudioId = getCurrentAudioId(getState());
      const currentVideoKey = getCurrentVideoKey(getState());
      if (id) {
        if (type === 'audio') {
          if (currentAudioId !== +id) dispatch(changeCurrentAudioId(+id));  
        }
        if (type === 'video') {
          if (currentVideoKey !== id) dispatch(changeCurrentVideoId(id));  
        }
      } else {
        if (currentAudioId !== +id) dispatch(changeCurrentVideoId(''));
        if (currentVideoKey !== id) dispatch(changeCurrentAudioId(0));
      }
    }
  }

}