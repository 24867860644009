import { API_URL } from '../../constants';
import { getDispatch, getState } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';
import { TVideoInfo } from '../../../spa/store/models/types';
import { RecentsActions } from '../../../spa/store/recents/actions';
import { getUserGroups } from '../../../spa/store/models/selectors';

export enum TUserRecentViewItemType {
  VIDEO = 0, AUDIO = 1
}

type TUserRecentViewVideoInfo = TVideoInfo
type TUserRecentViewAudioInfo = {
  languageId: number,
  userId: number
}

export type TUserRecentViewItem = {
  groupId: number,
  key: string,
  type: TUserRecentViewItemType,
  info: TUserRecentViewVideoInfo | TUserRecentViewAudioInfo
}

export class UserRecentViewRest {

  private static LIST_URL = API_URL + '/users/recentView';

  public static async list(limit: number): Promise<TUserRecentViewItem[]>  {
    const dispatch = getDispatch();
    const recents = await dispatch(postRequest(UserRecentViewRest.LIST_URL, {limit}));
    const groups = getUserGroups(getState());
    const recentsNoDeleted = recents.filter((item: TUserRecentViewItem) => {
      const group = groups.find(g => g.id === item.groupId);
      if (group) {
        const videoOrAudio = item.type === TUserRecentViewItemType.AUDIO
          ? group.audios && group.audios.find(i => String(i.id) === item.key)
          : group.videos && group.videos.find(i => i.videoKey === item.key);
        return !!videoOrAudio;
      }
      return false;
    });
    dispatch(RecentsActions.set(recentsNoDeleted));
    return recents;
  }

}