import { API_URL } from '../../constants';
import { TAsyncThunk } from '../../../spa/store/types';
import { TVideo } from '../../../spa/store/models/types';
import { postRequest } from '../../utils/requestService';
import { IVocabularyPhrase } from '../../../spa/types/common';

const VIDEO_REMOVE_URL = API_URL + '/video/remove';

export interface IRestRemoveGroupVideo extends TVideo {
  phraseCount: number;
  userPhraseCount: number;
}

export const removeGroupVideo = (
  videoKey: string,
  userGroupId: number
): TAsyncThunk => (
  dispatch
): Promise<IRestRemoveGroupVideo> => {
  const body = { videoKey, userGroupId };
  return dispatch(postRequest(VIDEO_REMOVE_URL, body));
};