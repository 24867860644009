import { AppThunk } from '../../../../../store/types';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { isCurrentAudioMovie } from '../../../../../store/current-video/selectors';
import { AudioPlayerManager } from '../../../../../effects/audioPlayer/audioPlayerManager';
import {
  getVideoTeacherNoteByPhraseId,
  getVideoTeacherPhraseById,
  getVideoTeacherUserActiveId
} from '../../../../../store/videos/selectors';
import {
  setVideoTeacherSelectPhraseIdAction,
  setVideoTeacherShowNoteIdAction,
  setVideoTeacherShowPhraseIdAction
} from '../../../../../store/videos/actions';
import {
  findLanguageById,
  getActiveUserGroup,
  getGroupNativeLanguage,
  getGroupTargetLanguage,
  getPhraseDetailsTabByType,
} from '../../../../../store/models/selectors';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { batch } from 'react-redux';
import { getPlayerState } from '../../../../../store/general/selectors';
import { PlayerStates } from '../../../../../types/common';
import { PhraseDetailsEffects } from '../../../../../effects/phrase-details/phrase-details-effects';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';
import { textSpeaker } from '../../../../../effects/textSpeaker';

export class TeacherPhraseEvents {

  public static onPlayPhrase(phraseId: number, isPause?: boolean): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (isPause) {
        PlayerController.getInstance().pause();
      } else {
        const userId = getVideoTeacherUserActiveId(getState());
        const phrase = getVideoTeacherPhraseById(getState(), userId, phraseId);
        if (phrase) {
          if (isCurrentAudioMovie(getState())) {
            AudioPlayerManager.getInstance().playPhrase(phrase);
          } else {
            PlayerController.getInstance().playTeacherPhrase(phrase);
          }
        }
      }
    }
  }

  public static onSpeechText(
    phraseId: number,
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().pause();
      const state = getState();
      const userId = getVideoTeacherUserActiveId(getState());
        const phrase = getVideoTeacherPhraseById(getState(), userId, phraseId);
      if (phrase) {
        let langCode = phrase.targetLangId && findLanguageById(state, phrase.targetLangId)?.code || '';
        textSpeaker.speak(phrase.highlighted, langCode, true, null, langCode);
      }
    }
  }

  public static onClickPhrase(phraseId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(PhraseListActions.setForwardPhraseId(phraseId));
      const state = getState();
      if (getPlayerState(getState()) === PlayerStates.PLAYING) {
        PlayerController.getInstance().pause();
      }
      const userId = getVideoTeacherUserActiveId(state);
      const phrase = getVideoTeacherPhraseById(state, userId, phraseId);
      const note = getVideoTeacherNoteByPhraseId(state, userId, phrase?.wordPhraseId || phrase?.id || 0);
      dispatch(setVideoTeacherShowPhraseIdAction(phrase?.id || 0));
      dispatch(PhraseDetailsActions.disActivate()); // hide prev translation
      if (note) {
        const noteTab = getPhraseDetailsTabByType(state, EPhraseDetailsTabType.NOTES);
        batch(() => {
          dispatch(setVideoTeacherShowNoteIdAction(note.id));
          dispatch(setVideoTeacherSelectPhraseIdAction(phraseId));
          dispatch(PhraseDetailsActions.setActiveTab(noteTab));
        })
      } else {
        const group = getActiveUserGroup(state);
        const targetLang = getGroupTargetLanguage(state, group);
        const nativeLang = getGroupNativeLanguage(state, group);
        const contextPhrase = getVideoTeacherPhraseById(state, userId, phraseId);
        PhraseDetailsEffects.showFromTeacherPhrase(targetLang, nativeLang, nativeLang, contextPhrase.highlighted, contextPhrase, false);
      }
    }
  }

  public static onOpenNote (
    phraseId: number
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(this.onClickPhrase(phraseId));
    }
  }

}