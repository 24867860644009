import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IPhraseTextEvents, IPhraseTextFields } from '../components/types';
import { PhraseText } from '../components/PhraseText';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseTextEvents } from './events';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';
import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';
import { IPhraseTextHOCProps } from './types';
import { PhraseDetailsPanelSelectors } from '../../selectors';

const mapStateToProps = (
	dispatch: any,
) => {
	return (
		state: IState,
		props: IPhraseTextHOCProps,
	): IPhraseTextFields => {
		let text = '';
		let context = '';
		let isContext;
		let isPhrase;
		let phraseId = PhraseDetailsPanelSelectors.getPhraseId(state);
		let phrase = PhraseDetailsPanelSelectors.getFullPhrase(state, phraseId);
		const { isTranslation } = props;

		if (phrase) {
			text = PhraseDetailsSelectors.getSelectText(state) || phrase.highlighted || '';
			context = phrase.fullPhrase || '';
			isContext = PhraseEffects.isContextExist(phrase);
			isPhrase = true;
		} else {
			text = PhraseContextEditorSelectors.getPhraseText(state)?.highlighted || '';
			isContext = PhraseContextEditorSelectors.getIsContext(state);
			if (isContext) {
				context = PhraseContextEditorSelectors.getPhraseContext(state)?.fullPhrase || '';
			}
		}
		const fromLangCode = PhraseDetailsSelectors.getFromLang(state)?.code || '';
		const isTranslateContext = PhraseContextEditorSelectors.getIsTranslateContext(state);

		return {
			context,
			fromLangCode,
			text,
			isContext,
			isPhrase,
			showEditContext: !isTranslation,
			isTranslateContext,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IPhraseTextEvents => ({
	  	onContextModify: () => dispatch(PhraseTextEvents.onContextModify()),
		onContextDelete: () => dispatch(PhraseTextEvents.onContextDelete()),
		onContextTranslate: () => dispatch(PhraseTextEvents.onContextTranslate()),
	}
);

export const PhraseTextHOC = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PhraseText);

PhraseTextHOC.displayName = 'PhraseTextHOC';
