import React from 'react';
import { alpha, Box, IconButton, Stack, SxProps, Tab, Tabs } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { PhraseDetailsContainerHOC } from '../../../PhraseDetails/PhraseDetailsContainer/hocs/PhraseDetailsContainerHOC';
import { PhraseListHOC } from '../../../PhraseList/hocs/PhraseListHOC';
import { IPhrasesColumnProps } from './types';
import { EPhrasesColumnType } from '../../../../../store/general/types';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { ArrowIcon } from '../../../../../../../icons/ArrowIcon';
import { PhrasesColumnContext, IPhrasesColumnContext } from '../../../../../../common/contexts/PhrasesColumnContext';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { CLASS_TABS_SAVED } from '../../../Video/components/constants';

export const PhrasesColumn: React.FC<IPhrasesColumnProps> = ({
	phrasesColumn,
	text,
	savedCount,
	setType,
}) => {

	const { isFullScreen } = React.useContext(DeviceContext);
	const [activeTab, _setActiveTab] = React.useState(0);
	const setActiveTab = (
		value: number,
	) => {
		_setActiveTab(value);
		setPhrasesColumnContext({
			tab: value,
		});
	};

	let [phrasesColumnBeforeFullscreen, setPhrasesColumnBeforeFullscreen] = React.useState(phrasesColumn);

	React.useEffect(() => {
		if (text) {
			setActiveTab(0);

			if (phrasesColumn === EPhrasesColumnType.HIDE) {
				setType(EPhrasesColumnType.COLUMNS_1);
			}
		}
	}, [text]);

	const isFullScreenPrev = React.useRef(null as any);
	React.useEffect(() => {
		if (isFullScreen) {
			if (!isFullScreenPrev.current) { // enter fulscreen
				setPhrasesColumnBeforeFullscreen(phrasesColumn);
				if (phrasesColumn === EPhrasesColumnType.COLUMNS_2) {
					setType(EPhrasesColumnType.COLUMNS_1);
				}
			}
		} else {
			if (isFullScreenPrev.current && // exit fulscreen
				phrasesColumnBeforeFullscreen === EPhrasesColumnType.COLUMNS_2 &&
				phrasesColumnBeforeFullscreen !== phrasesColumn
			) {
				setType(phrasesColumnBeforeFullscreen);
			}
		}
		isFullScreenPrev.current = isFullScreen;
		
	}, [isFullScreen]);

	const buttonSx: SxProps = {
		position: 'fixed',
		zIndex: 500,
		right: theme.spacing(.25),
		top: theme.spacing(3.75),
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),
		color: theme.palette.common.white,
		background: theme.palette.grey[500],
		borderRadius: '50%',

		'.MuiSvgIcon-root': {
			position: 'relative',
			zIndex: 100,
			width: theme.spacing(2),
			height: theme.spacing(1.5),
		},

		'&:before': {
			content: '""',
			position: 'absolute',
			left: theme.spacing(-.5),
			right: theme.spacing(-.5),
			top: theme.spacing(-.5),
			bottom: theme.spacing(-.5),
		},
	};

	let [phrasesColumnContext, _setPhrasesColumnContext] = React.useState<IPhrasesColumnContext>({
		tab: activeTab,
		changeTab: setActiveTab,
	});
	const setPhrasesColumnContext = (
		value: Partial<IPhrasesColumnContext>,
	) => {
		phrasesColumnContext = {
			...phrasesColumnContext,
			...value,
		};
		_setPhrasesColumnContext(phrasesColumnContext);
	};

	return <PhrasesColumnContext.Provider value={phrasesColumnContext}>
		{phrasesColumn === EPhrasesColumnType.COLUMNS_2 &&
			<>
				<PhraseDetailsContainerHOC />
				<PhraseListHOC />

				<IconButton
					sx={buttonSx}
					onClick={() => setType(EPhrasesColumnType.COLUMNS_1)}
				>
					<ArrowIcon />
				</IconButton>
			</>
		}
		{phrasesColumn === EPhrasesColumnType.COLUMNS_1 &&
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flex: '1 1 50%',
					maxWidth: theme.spacing(100),

					'.root-inner.fullscreen &': {
						mr: theme.spacing(2),
						maxWidth: theme.spacing(60),
					}
				}}
			>
				<Tabs
					value={activeTab}
					onChange={(e, value) => {
						setActiveTab(value)
					}}
					sx={{
						background: alpha(theme.palette.grey[50], .25),
						borderBottom: `1px solid ${theme.palette.grey[100]}`,

						'.MuiTab-root': {
							p: `0 ${theme.spacing(1.5)}`,
							maxWidth: 'none',
						},

						[`.${CLASS_TABS_SAVED}.flash`]: {
							animation: 'animation_tabs_flash .5s 3',
						},
		
						'@keyframes animation_tabs_flash': {
							'100%': { background: theme.palette.warning.light },
						},
					}}
				>
					<Tab
						label={'Translations & Prompts'}
					/>
					<Tab
						className={CLASS_TABS_SAVED}
						label={<Box
							sx={{
								display: 'inline',
							}}
						>
							Saved Phrases & Notes
							{!!savedCount &&
								<Box
									sx={{
										display: 'inline-flex',
										justifyContent: 'center',
										alignItems: 'center',
										height: theme.spacing(3),
										minWidth: theme.spacing(3),
										ml: theme.spacing(.5),
										p: theme.spacing(.25, .5),
										fontSize: theme.typography.subtitle1,
										color: theme.palette.grey[700],
										background: theme.palette.grey[100],
										borderRadius: theme.spacing(1.5),
									}}
								>{savedCount}</Box>
							}
						</Box>}
					/>
				</Tabs>

				<Box
					sx={{
						flex: `1 1 100%`,
						minHeight: 0,
						display: activeTab === 0 ? 'block' : 'none',
					}}
				>
					<PhraseDetailsContainerHOC />
				</Box>
				<Box
					sx={{
						flex: `1 1 100%`,
						minHeight: 0,
						display: activeTab === 1 ? 'block' : 'none',
						zIndex: 100, // hover onboarding hints over translation panel
					}}
				>
					<PhraseListHOC />
				</Box>

				{!isFullScreen &&
					<IconButton
						sx={{
							...buttonSx,
							transform: 'scaleX(-1)',
						}}
						onClick={() => setType(EPhrasesColumnType.COLUMNS_2)}
					>
						<ArrowIcon />
					</IconButton>
				}

				<IconButton
					sx={{
						...buttonSx,
						top: theme.spacing(8),
					}}
					onClick={() => setType(EPhrasesColumnType.HIDE)}
				>
					<ArrowIcon />
				</IconButton>
			</Paper>
		}
		{phrasesColumn === EPhrasesColumnType.HIDE && <>
			<IconButton
				sx={{
					...buttonSx,
					transform: 'scaleX(-1)',
				}}
				onClick={() => setType(EPhrasesColumnType.COLUMNS_1)}
			>
				<ArrowIcon />
			</IconButton>
		</>}
	</PhrasesColumnContext.Provider>
};
