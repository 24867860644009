import React from 'react';
import { Box, Stack } from '@mui/material';
import { IOneTimeTooltipTranslationsProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';

export function OneTimeTooltipTranslations ({
	show,
}: IOneTimeTooltipTranslationsProps) {
	if (!show) return null;

	const { isMobile } = React.useContext(DeviceContext);

	return !isMobile && (
		<Stack
			gap={.5}
			sx={{
				position: 'absolute',
				mt: theme.spacing(5),
				ml: theme.spacing(-.25),
				pointerEvents: 'none',
				color: theme.palette.grey[300],
				fontSize: theme.typography.subtitle2,
				fontWeight: 400,
				animation: 'animation_onetime_translations .5s forwards',

				'@keyframes animation_onetime_translations': {
					'0%': { transform: 'translateY(100px)', opacity: 0, },
					'100%': { transform: 'translateY(0)', opacity: 1, },
				},
			}}
		>
			<ArrowLightLeftIcon
				sx={{
					transform: 'rotate(90deg)',
				}}
			/>
			<Box>
				Set translation <br/>
				languages here
			</Box>
		</Stack>
	);
}
