import { EVocabularyPhraseType } from '../../../../../types/common';
import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Box, Stack } from '@mui/material';
import {
	CaptionHighlightLayers,
	ECaptionHighlightLayerType,
	TCaptionHighlightLayer
} from '../../../../../effects/phrase/utils/caption-highlight-layers';
import { renderToStaticMarkup } from 'react-dom/server';
import { HighLightedSpan } from '../../../../../../common/components/HighLightedText/HighLightedSpan';
import _ from 'lodash';
import { alpha, SxProps } from '@mui/system';
import { ITargetCaptionItemProps } from './types';
import { HtmlUtils } from '../../../../../../common/utils/html-utils';
import { SelectionUtil } from '../../../../../../common/utils/selection-util';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { PlayButton } from '../../../PlayButton/components/PlayButton';

const layerTypeBgColorMap = {
	[ECaptionHighlightLayerType.BG]: 'highlight-type_bg',
	[ECaptionHighlightLayerType.HIGHTLIGHT]: 'highlight-type_highlight',
	[ECaptionHighlightLayerType.WORD_SELECT]: 'highlight-type_word',
	[ECaptionHighlightLayerType.TRANSLATE_SELECT]: 'highlight-type_translate',
	[ECaptionHighlightLayerType.SELECTED_WORD]: 'highlight-type_word_preview',
	[ECaptionHighlightLayerType.SELECTED_CONTEXT]: 'highlight-type_highlight',
}

let marginHeight: number = -1;

const getMarginHeight = (elem: HTMLDivElement): number => {
	if (marginHeight >= 0) {
		return marginHeight;
	}
	marginHeight = HtmlUtils.getElementMaginsHeight(elem);
	return marginHeight;
}

enum ESelectMode {
	NONE, TEXT, CLICK
}

type TOnUpdateSelectModeCallback = (index: number, wordIndex: number) => void;

const onUpdateSelectMode = (mode: ESelectMode, callback: TOnUpdateSelectModeCallback, param: number, wordIndex: number) => {
	callback(param, wordIndex);
}
const updateSelectModeDebounce = _.debounce(onUpdateSelectMode , 300);

export const TargetCaptionItem: React.FC<ITargetCaptionItemProps> = ({
	index,
	isActive,
	startTime,
	endTime,
	captionText,
	phrases,
	phrasesHash,
	filterSelectContext,
	disableSelectMode,
	playerActive,
	onPlayPauseClick,
	onItemClick,
	onItemTextSelect,
	raiseMouseUpEvent,
	onMouseEnter,
	onMouseLeave,
	onMouseDown,
	onMouseUp,
	onPhraseHover,
	onSetHeight,
	selected,
	selectedWord
}) => {
	const { isFullScreen, isTouch } = React.useContext(DeviceContext);
	const [html, setHtml] = React.useState<string>('');
	const [selectMode, setSelectMode] = React.useState<ESelectMode>(ESelectMode.NONE);
	const [clickWordIndex, setClickWordIndex] = React.useState<number>(-1);
	const [lastSelWordIndex, setLastSelWordIndex] = React.useState<number>(-1);

	const rootElemRef = React.useRef<HTMLDivElement>(null);

	if (onSetHeight && rootElemRef.current) {
		const height = rootElemRef.current.offsetHeight;
		if (height) {
			const margin = getMarginHeight(rootElemRef.current);
			onSetHeight(index, height + margin);
		}
	}
/*
	const getUnderscorePos = (layerMap: TCaptionHighlightLayer[]) => {
		let start = -1;
		let end = -1;
		layerMap.forEach((l, index) => {
			if (l.underscore) {
				if (start < 0)
					start = index;
				end = index;
			}
		})
		return [start, end];
	}*/

	React.useEffect(() => {

		const text = _.unescape(captionText).replace(/[\n\r]/gm, ' ');

		const {map: layersMap, newLastSelWordEndIndex} = CaptionHighlightLayers.getLayers(startTime, endTime, captionText,
			index, phrases || [], filterSelectContext, false, lastSelWordIndex, selectedWord);
		setLastSelWordIndex(newLastSelWordEndIndex);
		if (
			!layersMap ||
			!layersMap.length ||
			!text ||
			!text.length
		) {
			setHtml('');
			return;
		}
		let _html = '';
		const len = text.length;

		const selPhrases = (phrases || []).filter(pi => pi.phrase.type === EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED);

	//	const [startUnderscore, endUnderscore] = getUnderscorePos(layersMap);

		const isHighlight = (type: ECaptionHighlightLayerType): boolean => {
			return type === ECaptionHighlightLayerType.HIGHTLIGHT;
		}
		const isWord = (type: ECaptionHighlightLayerType): boolean => {
			return type === ECaptionHighlightLayerType.WORD_SELECT || type === ECaptionHighlightLayerType.SELECTED_WORD;
		}

		for (let i = 0; i < len; i++) {
			if (i < layersMap.length) {
				const layerType = layersMap[i]?.type;
				const wordActive = layersMap[i]?.wordActive;
				
				const phrase = selPhrases.map(pi => pi.phrase).find(p => {
					if (p.startCaptionIndex === p.endCaptionIndex) {
						return i >= p.startPosition && i < p.endPosition;
					} else if (p.startCaptionIndex === index) {
						return p.startPosition <= i;
					} else if (p.endCaptionIndex === index) {
						return p.endPosition >= i;
					} else {
						return true;
					}
				});

				const hl = isHighlight(layerType);
				const word = isWord(layerType);
				let isBreak = (
					i === 0 && (word || hl) ||
					layersMap[i-1]?.type !== layerType
				);
				let isEnd = i === len - 1;
				if (isBreak) {
					if (i > 0) {
						_html += `</span>`;
					}
					if (word || hl) {
						_html += `<span class="hl-box ${layerTypeBgColorMap[layerType]}">`;
					}
				}
				_html += renderToStaticMarkup(<HighLightedSpan
					className={layerTypeBgColorMap[layerType]}
					isEnd={isEnd}
					// isStart={i === 0}
					// style={style}
					index={i}
					phraseId={phrase ? phrase.id : 0}
					word={word}
					wordActive={wordActive}
				>
					{text[i]}
				</HighLightedSpan>);

				if (isEnd && (word || hl)) {
					_html += `</span>`;
				}
			}
		}
		
		setHtml(_html);

	}, [/*phrases,*/ phrasesHash, selectedWord, selected]);

	React.useEffect(() => {
		if (isActive) {
			setLastSelWordIndex(0);
		}
	}, [isActive])

	React.useEffect(() => {
		if (raiseMouseUpEvent) {
			handleMouseUp();
		}
	}, [raiseMouseUpEvent])

	React.useEffect(() => {
		const setHeight = () => {
			if (onSetHeight && rootElemRef.current) {
				const height = rootElemRef.current.offsetHeight;
				if (height) {
					const margin = 0//getMarginHeight(rootElemRef.current);
					onSetHeight(index, height + margin);
				}
			}
		}
		
		if (onSetHeight && rootElemRef.current) {
			setHeight();
			new ResizeObserver(setHeight).observe(rootElemRef.current);
		}
	}, [isActive]);

	const elStyle: SxProps = {
		position: 'relative',
		zIndex: 100,
		width: '100%',
		lineHeight: theme.spacing(2.5),
		fontWeight: 400,
		fontSize: '18px',

		'.highlighted-span': {
			lineHeight: theme.spacing(2.5),
			fontSize: theme.typography.subtitle2,
			fontWeight: 400,
			verticalAlign: 'text-bottom',
		},

		'.hl-box': {
			background: theme.palette.info[100],

			'&.highlight-type_highlight' : {
				backgroundColor: theme.palette.info[500],
			},

			'&.highlight-type_word_preview' : {
				background: `linear-gradient(0deg, #d7aeff 15%, ${alpha(theme.palette.info[100], .5)} 20%)`,

				'.caption-item.selected &': {
					background: theme.palette.info[200],
				},
			},
		},

		'.root-inner.fullscreen &': {
			lineHeight: `1.7vw`,

			'.highlighted-span': {
				fontSize: `1.5vw`,
				lineHeight: `1.7vw`,
			},

			'.hl-box': {
				background: alpha(theme.palette.info[100], .2),
	
				'&.highlight-type_word_preview' : {
					background: `linear-gradient(0deg, #d7aeff 15%, ${alpha(theme.palette.info[100], .2)} 20%)`,
				},
			},
		},

		'.mobile .root-inner.fullscreen &': {
			lineHeight: `3.4vw`,

			'.highlighted-span': {
				fontSize: `3vw`,
				lineHeight: `3.4vw`,
			},

			'.hl-box': {
				background: alpha(theme.palette.info[100], .2),
	
				'&.highlight-type_word_preview' : {
					background: `linear-gradient(0deg, #d7aeff 15%, ${alpha(theme.palette.info[100], .2)} 20%)`,
				},
			},
		},


		'.hl-word': {
			background: theme.palette.common.white,
			animation: 'animation_active_word 3s forwards',
			
			'@keyframes animation_active_word': {
				'0%': { background: theme.palette.common.white, },
				'100%': { background: alpha(theme.palette.common.white, 0), }
			},
		},

		// '.caption-item.selected & .highlighted-span': {
		// 	backgroundColor: '#cee2fd',
		// },

		// '.phrase-context-editor & .highlight-type_word, .caption-item.selected & .highlight-type_word': {
		// 	backgroundColor: '#d7aeff',
		// },

		// '.highlight-type_bg' : {
		// 	backgroundColor: '#cee2fd',
		// },

		// '.highlight-type_word' : {
		// 	backgroundColor: theme.palette.info[100],
		// },

		// '&:hover .highlight-type_word' : {
		// 	backgroundColor: '#ffdf87',
		// },

		// '.highlight-type_word_preview' : {
		// 	backgroundColor: theme.palette.info[100],
		// },

		// '&:hover .highlight-type_word_preview' : {
		// 	backgroundColor: '#d7aeff'
		// },

		// '.phrase-context-editor & .highlight-type_word_preview, .caption-item.selected & .highlight-type_word_preview': {
		// 	backgroundColor: '#d7aeff'
		// },

		'.selected-onboarding': {
			position: 'absolute',
			zIndex: -1, // under text
			left: 0,
			top: 0,
			width: '100px',
			height: theme.spacing(3.25),
			transformOrigin: '0 0',
			pointerEvents: 'none',

			backgroundColor: '#d7aeff !important',
			animation: 'animation_captions_onboarding_selection 2s infinite',

			'@keyframes animation_captions_onboarding_selection': {
				'0%': { transform: 'scaleX(0)' },
				'60%': { transform: 'scaleX(1)', opacity: 1 },
				'75%, 100%': { opacity: 0 },
			},
		},
	};

	let boxStyle: SxProps = {
		position: 'relative',
		pt: theme.spacing(.5),
		pr: theme.spacing(1.5),
		pb: theme.spacing(.5),

		'&:hover .caption-item__play-icon': {
			opacity: .4,
		},

		'&.active': {
			'.caption-item__play': {
				background: `${theme.palette.primary[100]}`,
			},

			'.caption-item__play-icon': {
				opacity: 1,
			},
		},

		'.root-inner.fullscreen &': {
			pl: theme.spacing(1.5),
			color: alpha(theme.palette.common.white, .5),
			background: theme.palette.common.black,

			'&.active': {
				color: theme.palette.common.white,
			},
		},
	};

	const handlePlayPauseClick = () => {
		onPlayPauseClick(index);
	}

	const handleMouseDown = () => {
		onMouseDown(index);
	}

	React.useEffect(() => {
		if (selectMode === ESelectMode.CLICK) {
			updateSelectModeDebounce(selectMode, onItemClick, index, clickWordIndex);
		} else if (selectMode === ESelectMode.TEXT) {
			updateSelectModeDebounce(selectMode, onItemTextSelect, index, clickWordIndex);
		}
	}, [selectMode])

	const touchScroll = React.useRef<any>(null);
	const handleTouchStart = () => {
		const scroll = rootElemRef.current?.closest('.captions-parent');
		touchScroll.current = scroll ? scroll.scrollTop : 0;
	}

	const handleMouseUp = (event: MouseEvent) => {

		if (isTouch) {
			const scroll = rootElemRef.current?.closest('.captions-parent');
			let scrollNew = scroll ? scroll.scrollTop : 0;
			if (scrollNew - touchScroll.current !== 0) return; // scroll, not tap
		}

		const selText = SelectionUtil.getText();
		const wordIndex = event?.target?.getAttribute('data-word-index');
		setClickWordIndex(+wordIndex);

		if (selText) {
			setSelectMode(ESelectMode.TEXT)
		} else {
			setSelectMode(ESelectMode.CLICK)
		}
		setTimeout(() => {
			setSelectMode(ESelectMode.NONE)
		})
		onMouseUp(index);
	}


	const handleDoubleClick = () => {
		const selText = SelectionUtil.getText();
		if (selText) {
			setSelectMode(ESelectMode.TEXT)
		}
	}

	const handleMouseEnter = () => {
		onMouseEnter(index);
	}
	const handleMouseLeave = () => {
		onMouseLeave(index);
	}
	const raisePhraseHover = (phraseId: number) => {
		if (!disableSelectMode) {
			onPhraseHover(phraseId);
		}
	}
	const handleMouseMove = (e: MouseEvent) => {
		if (e.target) {
			// @ts-ignore
			const phraseId = parseInt(e.target.getAttribute('data-phrase-id'));
			// @ts-ignore
			const word: boolean = e.target.getAttribute('data-word') === '1';
			if (phraseId && word) {
				raisePhraseHover(phraseId);
			} else {
				raisePhraseHover(0);
			}
		}
		handleMouseEnter();
	}

	return (
		<Stack
			id={`caption-item-${index}`}
			ref={rootElemRef}
			className={`
			caption-item
			${isActive ? 'active' : ''}
			${selected ? 'selected' : ''}
		`}
			direction={'row'}
			sx={boxStyle}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onMouseMove={handleMouseMove}
		>
			{!isFullScreen &&
				<Box
					className={`caption-item__play`}
					sx={{
						position: 'absolute',
						zIndex: 0,
						left: theme.spacing(-4.25),
						right: theme.spacing(.25),
						top: 0,
						bottom: theme.spacing(.25),
						borderRadius: theme.spacing(2),
				
						'.captions-selection-popup-show &': {
							display: 'none',
						},

						'.caption-item__play-icon': {
							position: 'absolute',
							left: '1px',
							top: '3px',
							opacity: 0,

							'.caption-item:hover &:hover': {
								opacity: 1,
							},
						},
					}}
				>
					<PlayButton
						className={`caption-item__play-icon`}
						isPlay={playerActive}
						onClick={handlePlayPauseClick}
					/>
				</Box>
			}

			<Box
				className={'caption-item__letters'}
				sx={elStyle}
				data-start={startTime}
				data-end={endTime}
				dangerouslySetInnerHTML={{ __html: html ||
					`<span class="highlighted-span">${captionText}</span>` || ''}}
				onMouseDown={handleMouseDown}
				onMouseUp={handleMouseUp}
				onDoubleClick={handleDoubleClick}
				onTouchStart={handleTouchStart}
				onTouchEnd={handleMouseUp}
			/>
		</Stack>
	);

};