import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IPlaybackSettingsEvents, IPlaybackSettingsFields } from '../components/types';
import { PlaybackSettings } from '../components/PlaybackSettings';
import { IPlaybackSettingsHOCOwnProps } from './types';
import {
  getAutoPause,
  getAutoSync, getPlayerRate,
  getShowNativeCaptions,
  isHoverAutoPause, isPauseOnNote
} from '../../../../../store/general/selectors';
import { PlaybackSettingsEvents } from './events';
import { CaptionsSelectionPopupSelectors } from '../../../../../store/captions-selection-popup/selectors';
import { getNativeCaptions } from '../../../../../store/videos/selectors';
import { getLibraryGroup, getNativeLanguage, getTargetLanguage, getUserGroupLangKnown, getUserGroupLangToLearn } from '../../../../../store/models/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    {anchor}: IPlaybackSettingsHOCOwnProps
  ): IPlaybackSettingsFields => {
    const pause = getAutoPause(state);
    const autoSync = getAutoSync(state);
    const hoverAutoPause = isHoverAutoPause(state);
    const showNativeCaptions = getShowNativeCaptions(state);
    const isShowNativeCaptionsAvailable = !!getNativeCaptions(state).length;
    const autoSpeechText = CaptionsSelectionPopupSelectors.getAutoSpeechText(state);
    const rate = getPlayerRate(state);
    const pauseOnNote = isPauseOnNote(state);
    const group = getLibraryGroup(state);
    const nativeLang = (group ? getUserGroupLangKnown(state, group)?.name : getNativeLanguage(state)?.name) || '';
    const targetLang = (group ? getUserGroupLangToLearn(state, group)?.name : getTargetLanguage(state)?.name) || '';

    return {
      anchor,
      pause,
      autoSync,
      hoverAutoPause,
      showNativeCaptions,
      isShowNativeCaptionsAvailable,
      autoSpeechText,
      rate,
      pauseOnNote,
      nativeLang,
      targetLang
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {onClose}: IPlaybackSettingsHOCOwnProps
): IPlaybackSettingsEvents => ({
    onClose,
    onPauseSizeChange: pause => dispatch(PlaybackSettingsEvents.onPauseSizeChange(pause)),
    onAutoSyncChange: () => dispatch(PlaybackSettingsEvents.onAutoSyncChange()),
    onHoverAutoPauseChange: () => dispatch(PlaybackSettingsEvents.onHoverAutoPauseChange()),
    onShowNativeCaptionsChange: () => dispatch(PlaybackSettingsEvents.onShowNativeCaptionsChange()),
    onAutoSpeechTextChange: () => dispatch(PlaybackSettingsEvents.onAutoSpeechTextChange()),
    onRateChange: rate => dispatch(PlaybackSettingsEvents.onRateChange(rate)),
    onPauseOnNoteChange: () => dispatch(PlaybackSettingsEvents.onPauseOnNoteChange())
  }
);

export const PlaybackSettingsHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaybackSettings);

PlaybackSettingsHOC.displayName = 'PlaybackSettingsHOC';