import { getAuthUser } from '../../../spa/store/general/selectors';
import { getState } from '../../../spa/store';
import { EMainLayoutType, EPhrasesColumnType } from '../../../spa/store/general/types';

export class SyncStorage {
  constructor() {}
  getItem = <T>(key: string, defaultItem?: T): T => {
      const json = window.localStorage.getItem(key);
      return json ? JSON.parse(json) : defaultItem;
  }
  saveItem = <T>(key: string, item: T): this => {
    const json = JSON.stringify(item);
    window.localStorage.setItem(key, json);
    return this;
  }
}

export class Storage {
  constructor() {}

  getItem = <T>(key: string, defaultItem?: T): Promise<T> => {
    try {
      const json = window.localStorage.getItem(key) || '';
      const item = JSON.parse(json);
      if (!!item) {
        return Promise.resolve(item);
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = `Failed to read "${key}" from local storage`;
      console.log(error);
      return defaultItem ? Promise.resolve(defaultItem) : Promise.reject(error);
    }
  };

  setItem = <T>(key: string, item: T): Promise<void> => {
    try {
      const json = JSON.stringify(item);
      window.localStorage.setItem(key, json);
      return Promise.resolve();
    } catch (e) {
      const error = `Failed to write "${key}" to local storage. Value was: ${item}`;
      console.log(error);
      return Promise.reject(error);
    }
  };
}


export class GeneralStorage extends SyncStorage {

  default: TGeneralStorage;

  constructor(fold: TGeneralStorage) {
    super();
    this.default = fold;
  }

  private getStorageKey() {
    const user = getAuthUser(getState());
    return 'general' + (user ? '-' + user.hashId : '');
  }

  getData(): TGeneralStorage {
    return this.getItem(this.getStorageKey(), this.default);
  }
  saveData(value: TGeneralStorage): TGeneralStorage {
    this.saveItem(this.getStorageKey(), value);
    return value;
  }

  updateData(value: Partial<TGeneralStorage>) {
    const data: TGeneralStorage = {...this.getData(), ...value};
    this.saveData(data);
  }

  setAutoPause(value: number) {
    const data: TGeneralStorage = this.getData();
    data.autoPause = value;
    this.saveData(data);
  }

  setShowPinExtensionPopup(value: boolean) {
    const data: TGeneralStorage = this.getData();
    data.showPinExtensionPopup = value;
    this.saveData(data);
  }

  setShowTelegramBotPopup(value: boolean) {
    const data: TGeneralStorage = this.getData();
    data.showTelegramBotPopup = value;
    this.saveData(data);
  }

  setShowVideoPromptsTutorial(value: boolean) {
    const data: TGeneralStorage = this.getData();
    data.showVideoPromptsTutorial = value;
    this.saveData(data);
  }

  setFlashPhraseDetails(value: boolean) {
    const data: TGeneralStorage = this.getData();
    data.flashPhraseDetails = value;
    this.saveData(data);
  }

  setMainLayoutType(value: EMainLayoutType) {
    const data: TGeneralStorage = this.getData();
    data.mainLayoutType = value;
    this.saveData(data);
  }

  setShowLeftPanel(value: boolean) {
    const data: TGeneralStorage = this.getData();
    data.showLeftPanel = value;
    this.saveData(data);
  }

  setPhrasesColumn(value: EPhrasesColumnType) {
    const data: TGeneralStorage = this.getData();
    data.phrasesColumn = value;
    this.saveData(data);
  }
}

const storage = new GeneralStorage({
  autoPause: 0,
  showPinExtensionPopup: false,
  showTelegramBotPopup: false,
  showVideoPromptsTutorial: false,
  flashPhraseDetails: false,
  mainLayoutType: EMainLayoutType.COLUMNS_4,
  showLeftPanel: true,
  phrasesColumn: EPhrasesColumnType.COLUMNS_1,
});

export const getGeneralStorage = (): GeneralStorage => {
  return storage;
}


export type TGeneralStorage = {
  autoPause: number;
  showPinExtensionPopup: boolean;
  showTelegramBotPopup: boolean;
  showVideoPromptsTutorial: boolean;
  flashPhraseDetails: boolean;
  mainLayoutType: EMainLayoutType;
  showLeftPanel: boolean;
  phrasesColumn: EPhrasesColumnType;
}


