// all time fields are in seconds
import { YouTubePlayer } from 'youtube-player/dist/types';

export interface ICaptionsItemWord {
  text: string,
  time: number,
}

export interface ICaptionsItem {
  startTime: number;
  endTime: number;
  duration: number;
  text: string;
  words?: ICaptionsItemWord[]
}

export interface IComment {
  title: string;
  text: string;
  start: number;
  end: number;
}
export type TNewComment = Omit<IComment, 'id'>;

export type IYoutubePlayer = YouTubePlayer & {
  playerInfo: Record<string, any>;
  getVideoData: () => {
    author: string;
    title: string;
  };
};

export enum EVocabularyPhraseType {
  DEFAULT = 0,
  WORD_SELECTED = 1,
  WORD_AND_CONTEXT_SELECTED = 2,
  PREVIEW_WORD_SELECTED = 3,
  PREVIEW_WORD_AND_CONTEXT_SELECTED = 4,
  LESSON = 5, // not related to captions
}
export enum EVocabularyPhraseCreateType {
  USER = 0, COPY = 1
}

export type TNewVocabularyPhrase = Omit<IVocabularyPhrase, 'id'>;
export interface IVocabularyPhrase {
  id: number;
  fullPhrase: string;
  highlighted: string;
  translated: string;
  startTime: number; // startTime and endTime could be from different Captions items
  endTime: number;
  userGroupId?: number;
  videoKey?: string;
  type?: EVocabularyPhraseType;
  startCaptionIndex: number,
  endCaptionIndex: number,
  startPosition: number,
  endPosition: number,
  wordPhraseId: number,
  translate?: boolean,
  contextModified?: boolean,
  srcTeacherPhraseId?: number,
  selected?: boolean
  targetLangId?: number; // backend TTeacherPhraseBase
  createType?: EVocabularyPhraseCreateType;
}

export interface IYoutubeVideoInfo {
  title: string;
  thumbnailUrl: string;
  thumbnailMediumUrl: string;
  thumbnailStandardUrl: string;
  channelTitle: string;
  defaultAudioLanguage?: string;
}

export interface IYoutubeVideoThumbnail {
  url: string;
  width: number;
  height: number;
}

export interface IYoutubeVideoInfoFull {
  publishedAt: string;
  channelId: string;
  title: string;
  description: string;
  thumbnails: {
      default: IYoutubeVideoThumbnail;
      medium: IYoutubeVideoThumbnail;
      high: IYoutubeVideoThumbnail;
      standard: IYoutubeVideoThumbnail;
      maxres: IYoutubeVideoThumbnail;
  },
  channelTitle: string;
  categoryId: string;
}

export interface IPhraseNoteAuthorInfo {
  id: number,
  name: string,
  picture: string
}

export interface IPhraseNote {
  id: number;
  text: string;
  phraseId: number,
  pause: boolean,
  authorInfo?: IPhraseNoteAuthorInfo
}

export const ytElementId = 'yt-player';

export enum PlayerStates {
  BUFFERING = 3,
  ENDED = 0,
  PAUSED = 2,
  PLAYING = 1,
  UNSTARTED = -1,
  VIDEO_CUED = 5,
}

export enum AudioPlayerStates {
  PAUSED = 2,
  PLAYING = 1,
}

export const LANG_CODE_AUTO_GEN_SUBSTR = '_autogen';