import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { getMainViewMode } from '../../../../../store/general/selectors';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseDetailsEffects } from '../../../../../effects/phrase-details/phrase-details-effects';
import { EMainViewMode } from '../../../../../store/general/types';

export class PhraseDetailsContainerEvents {

  public static onLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const mainViewMode = getMainViewMode(getState());
      if (mainViewMode === EMainViewMode.PHRASE_DETAILS) return; // mobile
    }
  }

  public static onSelection(selection: Selection): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const anchorElement = selection?.anchorNode?.parentElement;
      if (anchorElement) {
        const activeTab = PhraseDetailsSelectors.getActiveTab(getState());
        if (activeTab?.type === EPhraseDetailsTabType.NOTES) {
          return;
        }
        dispatch(PhraseDetailsActions.setPopupMenu({
          show: true,
          text: selection.toString(),
          anchorElement
        }))
      }
    }
  }

  public static onPlayerPositionChange(position: number): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PhraseDetailsEffects.updateOnChangePlayerPosition(position);
    }
  }

}