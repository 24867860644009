import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { ContentLibraryHeader } from '../components/ContentLibraryHeader';
import { IContentLibraryHeaderEvents, IContentLibraryHeaderFields } from '../components/types';
import { ContentLibraryHeaderEvents } from './events';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { getMainViewMode } from '../../../../../store/general/selectors';
import { EMainViewMode } from '../../../../../store/general/types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IContentLibraryHeaderFields => {
    
    const mainViewMode = getMainViewMode(state) || EMainViewMode.CONTENT_LIBRARY;

    return {
      viewMode: ContentLibSelectors.getViewMode(state),
      mainViewMode,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IContentLibraryHeaderEvents => ({
    onChangeViewMode: viewMode => dispatch(ContentLibraryHeaderEvents.onChangeViewMode(viewMode)),
  }
);

export const ContentLibraryHeaderHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibraryHeader);

ContentLibraryHeaderHOC.displayName = 'ContentLibraryHeaderHOC';
