import { AppThunk } from '../../../../../store/types';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';
import { StatLogManager } from '../../../../../../common/stats/statLogManager';
import { DateUtil } from '../../../../../../common/utils/dateUtil';
import { setVideoWatchTodayAction } from '../../../../../store/general/actions';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';
import { getMainViewMode } from '../../../../../store/general/selectors';
import { EMainViewMode } from '../../../../../store/general/types';
import { StatRest } from '../../../../../../common/rest/stats/statRest';

export class DailyGoalEvents {

	public static onClick (): AppThunk {
		return (
			dispatch
		) => {
			dispatch(MainLayoutEffects.showStatistics());
			EventsRouter.trackEvent(Events.DAILY_GOAL_CLICKED);
		}
	}


	public static onLoad (): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const mainViewMode = getMainViewMode(getState());
			const isTotal = mainViewMode === EMainViewMode.CONTENT_LIBRARY ||
				mainViewMode === EMainViewMode.MY_LIBRARY;
			if (isTotal) {
				return StatRest.getStatVideoWatchTotal().then(ret => {
					return Math.floor(ret / 60);
				});
			}

			const todayDate = DateUtil.trimToDay(new Date());

			return StatLogManager.getStat([{
				id: 'today',
				from: todayDate,
        		to: DateUtil.incDays(todayDate, +1),
			}])
				.then((ret) => {
					if (ret && ret.resultList) {
						let list = ret.resultList;
						if (list.length) {
							let secArr = Object.values(list[0].videoWatch);
							let secs = secArr && secArr.length && secArr.reduce((mins, i) => {
								return mins + i;
							}, 0) || 0;
							dispatch(setVideoWatchTodayAction(secs));
							return secs;
						}
					}
				});
		}
	}


}