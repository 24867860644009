import { Reducer } from 'redux';
import { IRecentsState, RECENTS_SET_ACTION, TRecentsActions } from './types';

const initialState: IRecentsState = {
  list: [],
};

export const recentsReducer: Reducer<IRecentsState, TRecentsActions> = (
  state = initialState,
  action
) => {

  switch (action.type) {
    case RECENTS_SET_ACTION:
      return {
        ...state,
        list: [...action.list],
      };
      
    default:
      return state;
  }
};