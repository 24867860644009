import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { getOneTimeTooltip } from '../../../../../store/general/selectors';
import { OneTimeTooltipTranslations } from '../../components/OneTimeTooltipTranslations/OneTimeTooltipTranslations';
import { IOneTimeTooltipTranslationsEvents, IOneTimeTooltipTranslationsFields } from '../../components/OneTimeTooltipTranslations/types';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IOneTimeTooltipTranslationsFields => {
		const oneTimeTooltips = getOneTimeTooltip(state);
		let show = oneTimeTooltips.showTranslations;
		
		return {
			show,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IOneTimeTooltipTranslationsEvents => ({
});

export const OneTimeTooltipTranslationsHOC = connect(
	mapStateToProps,
	null,
)(OneTimeTooltipTranslations);

OneTimeTooltipTranslationsHOC.displayName = 'OneTimeTooltipTranslationsHOC';
