import { AppThunk } from '../../../../../store/types';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { ContentLibEvents } from '../../effects';
import { isAuthorised } from '../../../../../store/general/selectors';
import { setCopyGroupOnLoginAction } from '../../../../../store/general/actions';
import { Navigator } from '../../../../../effects/navigator';
import { getUserGroupById } from '../../../../../store/models/selectors';

export class ContentLibGroupInfoEvents {

  public static onCopyGroup(contentGroupId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (isAuthorised(getState())) {
        const groupNew = await ContentLibEffects.copyGroup(contentGroupId);
        const group = getUserGroupById(getState(), groupNew.id);
        if (group && group.videos && group.videos.length) {
          Navigator.openVideo(group.id, group.videos[0].videoKey);
        } else {
          dispatch(ContentLibActions.setCopyGroupConfirm({show: true, groupId: groupNew.id}));
        }
      } else {
        dispatch(setCopyGroupOnLoginAction({contentGroupId}));
        Navigator.openDefault();
      }
    }
  }

  public static onSelectGroup(groupId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(ContentLibEvents.openGroup(groupId));
    }
  }


}