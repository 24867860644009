import * as React from 'react';
import { Avatar, Button, Menu, MenuItem } from '@mui/material';
import { IAccountButtonProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { LoginAsItem } from '../../LoginAsItem/LoginAsItem';
import { CreatePublicProfileModalHOC } from '../../../PublicProfile/CreatePublicProfileModal/hocs/CreatePublicProfileModalHOC';

export const AccountButton: React.FC<IAccountButtonProps> = ({
  userExist,
  userName,
  userPicture,
  isAdmin,
  closeMenu,
  onSignOut,
  onSwitchAccount,
  onShowAdminPanel,
  onLoginAs,
    onOpenPublicAccount,
}) => {
  if (!userExist) return null;

    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
    const [showMenu, setShowMenu] = React.useState<boolean>(false);

    const handleClickButton = (e: React.MouseEvent<HTMLElement>) => {
      setAnchor(e.currentTarget);
      setShowMenu(true);
    }

    const handleClose = () => {
      setAnchor(null);
      setShowMenu(false);
    }

    const handleSwitchAccount = () => {
      setShowMenu(false);
      onSwitchAccount();
    }

    const handlePublicProfile = () => {
        setShowMenu(false);
        onOpenPublicAccount();
        if (closeMenu) closeMenu();
    };

    const handleSignOut = () => {
      setShowMenu(false);
      onSignOut();
    }

    const handleLoginAs = (email: string) => {
      onLoginAs(email);
    }



    const avatar =
        <Avatar
            src={userPicture}
            alt={userName}
            sx={{ width: theme.spacing(5), height: theme.spacing(5) }}
        />;

    const buttonStyle = {
        '.MuiButton-startIcon': {
            margin: `0 !important`
        }
    };

    return (
        <>
          <Button
            onClick={handleClickButton}
            startIcon={avatar}
            sx={buttonStyle}
          />
            <Menu
                id='account-menu'
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={showMenu}
                onClose={handleClose}
                sx={{
                  mt: -1,
                  ml: 2,
                }}
            >
              {isAdmin &&
                <>
                <MenuItem onClick={onShowAdminPanel}>Admin Console</MenuItem>
                <MenuItem>
                  <LoginAsItem onClick={handleLoginAs}/>
                </MenuItem>
                </>
              }
              {/*  <MenuItem onClick={onShowGptPrompts}>Prompt presets</MenuItem>*/}
                <MenuItem onClick={handlePublicProfile}>Public profile</MenuItem>
                <MenuItem onClick={handleSwitchAccount}>Switch account</MenuItem>
                <MenuItem onClick={handleSignOut}>Logout</MenuItem>
            </Menu>

          <CreatePublicProfileModalHOC />
        </>
    );
};
