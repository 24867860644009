import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { alpha, ListItem, ListItemButton, Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { IAudioLibraryGroupItemProps } from './types';
import { AudioIcon } from '../../../../../../../icons/AudioIcon';
import LangFlag from '../../../LangFlag/LangFlag';

export function AudioLibraryGroupItem ({
	author,
	isOpened,
	title,
	langCodes,
	onClick,
}: IAudioLibraryGroupItemProps) {

	const handleClick = () => {
		let modal;
		setTimeout(() => {
			modal = document.querySelector('.recent-drawer > .MuiModal-backdrop') as HTMLElement;
			if (modal) modal.click();
		}, 100);
		setTimeout(() => {
			modal = document.querySelector('.menu-drawer > .MuiModal-backdrop') as HTMLElement;
			if (modal) modal.click();
		}, 200);
		onClick();
	}

	return (
		<ListItem
			className={`video-library-item ${isOpened ? 'opened' : ''}`}
			sx={{ p: 0 }}
		>
			<ListItemButton
				onClick={handleClick}
				sx={{
					p: theme.spacing(),
					borderRadius: theme.spacing(0.75),

					'.video-library-item.opened &': {
						background: `${theme.palette.primary[100]} !important`,
					}
				}}
			>
				<Stack
					direction={'row'}
					gap={theme.spacing(2)}
					sx={{
						width: 1,
						alignItems: 'center',
						pr: theme.spacing(4),
						overflow: 'hidden',
					}}
				>
					<Stack
						justifyContent={'center'}
						alignItems={'center'}
						sx={{
							flex: `0 0 ${theme.spacing(14.25)}`,
							aspectRatio: 16/9,
							borderRadius: theme.spacing(0.75),
							background: theme.palette.grey[50],

							'.video-library-item.opened &': {
								background: alpha(theme.palette.common.white, .75),
							}
						}}
					>
						<AudioIcon
							sx={{
								width: theme.spacing(3),
								height: theme.spacing(3),
								color: theme.palette.grey[400],

								'.video-library-item.opened &': {
									color: theme.palette.primary[400],
								}
							}}
						/>
					</Stack>
					<Stack
						gap={'3px'}
						sx={{
							flex: `1 1 100%`,
						}}
					>
						<Text
							fontWeight={600}
							sx={{
								display: '-webkit-box',
								WebkitLineClamp: 2,
								WebkitBoxOrient: 'vertical',
								overflow: 'hidden'
							}}
						>
							{title}
						</Text>
						<Text
							fontWeight={600}
							noWrap
							sx={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								color: theme.palette.grey[400]
							}}
						>
							{author}
						</Text>
						{!!langCodes && !!langCodes.length &&
							<Stack
								direction='row'
								sx={{ height: theme.spacing(2) }}
								spacing={theme.spacing()}
							>
								{langCodes.map((langCode) => (
									<LangFlag code={langCode} showCode={true} key={langCode}/>
								))}
							</Stack>
						}
					</Stack>
				</Stack>
			</ListItemButton>

		</ListItem>
	);
};
