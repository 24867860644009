import { AppThunk } from '../../../../store/types';
import { setShowAddNewAudioPopupAction } from '../../../../store/general/actions';
import { AudioEffects } from '../../../../effects/audioEffects';

export class AddNewAudioPopupEvents {

  public static onClose(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setShowAddNewAudioPopupAction(false));
    }
  }


  public static onCreate(text: string, langCode: string): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      await AudioEffects.add(text, langCode);
    }
  }

  public static onTextChange(text: string): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      return await AudioEffects.detectLang(text);
    }
  }




}